import {createAsyncThunk} from "@reduxjs/toolkit";

import NotificationsService from "../../../api/NotificationsService";

export const asyncGetAllNotificationsAction = createAsyncThunk(
    'notifications/getAllNotifications',
    async (query:any, thunkAPI)=>{
        try {
            const {page,limit,sort } = query;
            let response = await NotificationsService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncGetCountNotificationsAction = createAsyncThunk(
    'notifications/getCount',
    async (_, thunkAPI)=>{
        try {
            let response = await NotificationsService.getCount();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);