import {FC} from 'react';

const SearchIcon:FC = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.2232 0C7.93707 0 4.44712 3.48989 4.44712 7.776C4.44712 9.63837 5.10031 11.3453 6.19673 12.6851L0 18.8802L1.11897 20L7.31493 13.8032C8.6987 14.9377 10.4338 15.5559 12.2232 15.5521C16.5094 15.5521 19.9992 12.0622 19.9992 7.776C19.9992 3.48989 16.5094 0 12.2232 0ZM12.2232 1.55521C15.6679 1.55521 18.444 4.33126 18.444 7.776C18.444 11.2209 15.6679 13.9968 12.2232 13.9968C8.77842 13.9968 6.00233 11.2209 6.00233 7.776C6.00233 4.33126 8.77842 1.55521 12.2232 1.55521Z" fill="#052924"/>
        </svg>
    );
};

export default SearchIcon;