import React, {FC, ReactNode, useEffect, useState} from 'react';
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import PlusIcon from "../../atoms/icons/PlusIcon";
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../old/atoms/ErrorMessage/ErrorMessage";
import CloseIcon from "../../atoms/icons/CloseIcon";
import Select from "../../atoms/Select/Select";

import './RepeaterColors.scss'
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import { asyncGetSearchProductsAction} from "../../../redux/slices/products/productsAction";

interface IOptionItem{
    placeholder: string;
    name: string;
    type: 'input' | 'textarea' | "inputHex";
    onChange: ()=>void;
    onBlur: ()=>void;
    value:string;
}

interface IRepeater{
    name:string;
    title?:ReactNode;
    onAddItem?:()=>void;
    onDeleteItem?:(id:number)=>void;
    handleBlur?:()=>void;
    handleChange?:()=>void;
    items: any[];
    values:any;
    errors:any;
    touched:any;
    category:number;
    setFieldValue:any;
}

const RepeaterColors:FC<IRepeater> = ({name,
                                    title,
                                    items=[],
                                    onAddItem=()=>{},
                                    onDeleteItem=()=>{},
                                    handleChange=()=>{},
                                    handleBlur=()=>{},
                                          setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    category
}) => {
    const [open,setOpen] = useState<boolean>(false);
    const [list,setList] = useState<any[]>([]);
    const dispatch = useDispatchEx();
    useEffect(() => {
           dispatch(asyncGetSearchProductsAction({
               page:1,
               limit:100,
               sort:'asc',
              category:category
           }))
       }, [category]);

    const {products} = useSelectorEx(state => state.products);
    useEffect(() => {
        const productsList = products.map((item:any)=>{
            return {
                name: item.title as string,
                value: item.id.toString(),
            }
        })
        setList(productsList);
    //    console.log(items,productsList)
    }, [products]);



    const onAddItemEx = ()=>{
        setOpen(true);
        onAddItem();
    }
    return (
        <div className={`repeater-color ${open ? "active": ""}`}>
            <div className="repeater-color__header">
                <div className="repeater-color__title" onClick={()=>setOpen(!open)}><span>{title}</span>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>
                <div className="repeater-color__button">
                    <ButtonRect onClick={onAddItemEx} name={<PlusIcon/>} />
                </div>
            </div>
            <div className="repeater-color__wrapper">
                <div className="repeater-color__items">
                    {items.map(((item,index)=>{
                        return  <div key={index} className="repeater-color__item">
                            <div className="repeater-color__item-inner">
                                <div className="repeater-color__item-inputs">
                                    <div className="repeater-color__item-input">
                                        <Input className={errors && touched ? "error": ""} type={'hex'} value={values[index]?.color} onBlur={handleBlur} onChange={handleChange} name={`${name}[${index}].color`} placeholder={'Hex'} />
                                    </div>
                                    <div className="repeater-color__item-input">
                                        <Input className={errors && touched ? "error": ""} value={values[index]?.name} onBlur={handleBlur} onChange={handleChange} name={`${name}[${index}].name`} placeholder={'Назва'} />
                                    </div>
                                </div>
                                <div className="repeater-color__item-button">
                                    <ButtonRect onClick={()=>onDeleteItem(index)} colorType={'red'} name={<CloseIcon/>}/>
                                </div>
                            </div>
                            <div className="repeater-color__item-select">
                                {list?.length ?
                                    <Select
                                        type={'search'}
                                        defaultValue={values[index]?.link?.toString()}
                                        className={errors && touched ? "error": ""}
                                        onChange={(value)=>setFieldValue(`${name}[${index}].link`, value)}
                                        placeholder={'Продукт'}
                                        items={list}
                                    />

                                    : ''

                                }


                            </div>
                        </div>
                    }))}

                </div>
            </div>

        </div>
    );
};

export default RepeaterColors;