import instance from "./api";
import {AxiosResponse} from 'axios';
import {IArticle} from "../helpers/types";

export default class ArticlesService {
    static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<any>> {
        return instance.get<IArticle[]>('articles',{
            params: {
                sort,
                limit,
                page
            }
        } )
    }

    static async findOne(articleID:number): Promise<AxiosResponse<IArticle>> {
        return instance.get<IArticle>(`articles/${articleID}` )
    }

    static async create(article:FormData): Promise<AxiosResponse<IArticle>> {
        return instance.post<IArticle>(`articles`,article)
    }

    static async update(article:FormData,articleID:number): Promise<AxiosResponse<IArticle>> {
        return instance.put<IArticle>(`articles/${articleID}`,article)
    }

    static async delete(articleID:number): Promise<AxiosResponse<IArticle>> {
        return instance.delete<IArticle>(`articles/${articleID}`)
    }
}