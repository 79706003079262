import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncCreateAttributeAction, asyncDeleteAttributeAction,
    asyncGetAttributeAction,
    asyncGetAttributesAction,
    asyncUpdateAttributeAction
} from "./attributesAction";
import {IAttribute} from "../../../helpers/types";

interface attributesState {
    attributes: IAttribute[];
    attribute: IAttribute | null
    isLoading: boolean;
    error: string
}

const initialState: attributesState = {
    attributes: [],
    attribute: null,
    isLoading: false,
    error: ''
};


const attributesSlice = createSlice({
    name:'attributesSlice',
    initialState,
    reducers:{
        resetAttributes(state){
            state = initialState;
        },
    },
    extraReducers: {
        [asyncGetAttributesAction.fulfilled.type]: (state, action: PayloadAction<any>) => {

            state.isLoading = false;
            state.error = '';
            state.attributes = action.payload;
        },
        [asyncGetAttributesAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetAttributesAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.attribute = action.payload;
        },
        [asyncGetAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncUpdateAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.attribute = action.payload;
            message.success('Attribute created');
        },
        [asyncUpdateAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncCreateAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.attributes.push(action.payload);
            message.success('Attribute created');
        },
        [asyncCreateAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncCreateAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncDeleteAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.attributes = state.attributes.filter(item=>item.id !== action.payload.id)
            message.success('Attribute deleted');
        },
        [asyncDeleteAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
    }
});

export const {resetAttributes} = attributesSlice.actions;
export default attributesSlice.reducer;