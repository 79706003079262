import React, {ChangeEvent, FC, useRef} from 'react';
import './UploaderFile.scss';
import ButtonRect from "../ButtonRect/ButtonRect";
import CloseIcon from "../icons/CloseIcon";
import ImportIcon from "../icons/ImportIcon";

interface IUploaderImage {
    id?: string;
    title?: string;
    name?: string;
    onChange:(e:ChangeEvent<HTMLInputElement>)=>void;
    onDelete?:()=>void;
    defaultValue?: string;
    value?: {
        name?: string;
        format?: 'text/csv';
    };
}

const UploaderFile:FC<IUploaderImage> = ({title,name,id,onChange,defaultValue='', value, onDelete=()=>{}}) => {
    const inputRef = useRef<any>();
    const onDeleteEx = ()=>{
        onDelete();
        inputRef.current.value = "";
    };
   // const image = value !== undefined ? (typeof value === "string" ?  value : URL.createObjectURL(value)) : defaultValue

    const formatsList = {
        'text/csv' : "CSV"
    }

    return (
        <div className={'uploader-file'}>
            {value ?
                <div className="uploader-file__file">
                    <div className="uploader-file__file-wrapper">
                        <div className={'uploader-file__file-format'}>
                            {value?.format && formatsList[value.format] ? formatsList[value.format] : <span style={{color:"#ff0000"}}>Error</span>}
                        </div>
                        <span>{value?.name}</span>
                    </div>

                    <div className="uploader-file__controls">
                        <ButtonRect className={'btn-rect--small'} onClick={()=>onDeleteEx()} colorType={'red'} name={<CloseIcon/>}/>
                    </div>
                </div>
                :
                <div className="uploader-file__title">
                    <ImportIcon/>
                    <span>{title}</span>
                </div>

            }

            <input ref={inputRef} id={id} name={name} accept="image/*" type="file" onChange={onChange} />
        </div>
    );
};

export default UploaderFile;