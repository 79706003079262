import React, {useEffect} from 'react';
import {useParams} from "react-router";

import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import { asyncGetAttributeAction, asyncUpdateAttributeAction} from "../../redux/slices/attributes/attributesAction";
import Preloader from "../../components/atoms/Preloader/Preloader";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormAttribute from "../../components/molecules/FormAttribute/FormAttribute";
import CategoryTagsList from "../../components/molecules/CategoryTagsList/CategoryTagsList";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";

const Attribute = () => {
    let {attributeID} = useParams();

    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            name: '',
            values: [],
            categoryId: ''
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
        const changedValues = {
            ...values,
            values: values.values.join(',')
        }
        dispatch(asyncUpdateAttributeAction({id:Number(attributeID), ...changedValues}));
    };
    useEffect(()=>{
        dispatch(asyncGetAttributeAction(Number(attributeID)));
    },[attributeID]);

    const {isLoading,attribute} = useSelectorEx(state => state.attributes)
    useEffect(()=>{
        if(attribute){
            config.setFieldValue('name',attribute?.name);
            config.setFieldValue('values',attribute?.values?.split(','));
            config.setFieldValue('categoryId',attribute?.categoryId);
        }

    },[attribute]);
 //   if(isLoading) return <Preloader/>;
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('attributes_pages.page.title')} onSave={()=>config.handleSubmit()}  />
                {!isLoading ?
                    <>
                        <FormAttribute
                            title={''}
                            config={config}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }


            </div>
{/*            <div className="page__header">
                <h1 className="h1">Attribute {attributeID}</h1>
                <Button onClick={()=>config.handleSubmit()} type="primary" className={'button-green'}><FontAwesomeIcon className={'icon-button'} icon={faFloppyDisk} /> Save Attribute</Button>
            </div>
            <AttributeForm title={'Update attribute'} config={config}  />*/}
        </>
    );
};

export default Attribute;