import { createAsyncThunk } from "@reduxjs/toolkit";
import SupportsService from "../../../api/SupportsService";

export const asyncGetSupportsAction = createAsyncThunk(
    'supports/all',
    async (query: any, thunkAPI) => {
        try {
            const { sort, page, limit } = query;
            let response = await SupportsService.findAll(sort, limit, page);
            return response.data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message);
        }
    }
);

export const asyncCreateSupportMessageAction = createAsyncThunk(
    'supports/create',
    async (values: any, thunkAPI) => {
        try {

            const formData = new FormData();

            formData.append('userId', values.userId);
            formData.append('message', values.message);
            console.log(`files`,values.files)
            if(values.files && values.files.length > 0){
                for(let file of values.files as File[]) {
                    formData.append('files', file as File);
                }
            }

            let response = await SupportsService.create(formData);
            return response.data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message);
        }
    }
);

export const asyncGetSupportAction = createAsyncThunk(
    'supports/one',
    async (id: number, thunkAPI) => {
        try {
            let response = await SupportsService.findOne(id);
            return response.data;
        } catch (e: any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message);
        }
    }
);

