import {createAsyncThunk} from "@reduxjs/toolkit";
import AttributesService from "../../../api/AttributesService";
import {IArticle, IAttribute} from "../../../helpers/types";


export const asyncGetAttributesAction = createAsyncThunk(
    'attributes/all',
    async (category:any, thunkAPI)=>{
        try {
            let response = await AttributesService.findAll(category);
            return response.data.reverse();
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetAttributeAction = createAsyncThunk(
    'attributes/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await AttributesService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateAttributeAction = createAsyncThunk(
    'attributes/create',
    async (attribute:IAttribute, thunkAPI)=>{
        try {
            let response = await AttributesService.create(attribute);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateAttributeAction = createAsyncThunk(
    'attributes/update',
    async (attribute:IAttribute, thunkAPI)=>{
        try {
            let response = await AttributesService.update(attribute, attribute.id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteAttributeAction = createAsyncThunk(
    'attributes/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await AttributesService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);