import React from 'react';
import {useDispatchEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {asyncCreateInfoAttributeAction} from "../../redux/slices/infoAttributes/infoAttributesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormInfoAttribute from "../../components/molecules/FormInfoAttribute/FormInfoAttribute";
import {useTranslation} from "react-i18next";


const CreateInfoAttribute = () => {
    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            name: '',
            values: [],
            categoryId: '',
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
        const changedValues = {
            ...values,
            values: values.values.join(',')
        }
        dispatch(asyncCreateInfoAttributeAction(changedValues));
        config.resetForm();
    };
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('info_attributes_pages.create.title')} onSave={()=>config.handleSubmit()}  />
                <FormInfoAttribute
                    title={''}
                    config={config}
                />

            </div>
        </>
    );
};

export default CreateInfoAttribute;