import instance from "./api";
import {AxiosResponse} from 'axios';
import {ISupportMessage} from "../helpers/types";


export default class SupportsService {
    static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<ISupportMessage>> {
    return instance.get('supports' ,
        {
          params: {
            sort,
            limit,
            page
          }
        })
    }
    static async create(formData:any): Promise<AxiosResponse<ISupportMessage>> {
        return instance.post<ISupportMessage>(`supports` ,formData)
    }
    static async findOne(orderID:number): Promise<AxiosResponse<ISupportMessage>> {
    return instance.get<ISupportMessage>(`supports/${orderID}` )
    }

/*  static async update(order:any,orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.put<IOrder>(`transactions/${orderID}`,order)
  }

  static async delete(orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.delete<IOrder>(`transactions/${orderID}`)
  }*/
}