import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncCreateProductAction,
    asyncDeleteProductAction,
    asyncGetProductAction,
    asyncGetProductsAction, asyncGetSearchProductsAction,
    asyncUpdateProductAction
} from "./productsAction";
import {IProduct} from "../../../helpers/types";
import {resetCategories} from "../categories/categoriesSlice";


export interface productsState {
    products: IProduct[];
    product: IProduct | null;
    productsSearch:IProduct[];
    shouldRedirectProduct:boolean;
    totalProducts:number;
    isLoading: boolean;
    error: string
}

const initialState: productsState = {
    products: [],
    productsSearch: [],
    shouldRedirectProduct:false,
    product: null,
    totalProducts: 0,
    isLoading: false,
    error: ''
};


const productsSlice = createSlice({
    name:'productsSlice',
    initialState,
    reducers:{
        changeRedirect(state,action){
            state.shouldRedirectProduct = action.payload;
        },
        resetProducts(state){
            state = initialState;
        },
    },
    extraReducers: {
        [asyncGetSearchProductsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
          //  state.isLoading = false;
            state.error = '';
            state.totalProducts = count;
            state.products = rows;
        //    message.success('Products received 1');
        },
        [asyncGetSearchProductsAction.pending.type]: (state) => {
         //   state.isLoading = true;
        },
        [asyncGetSearchProductsAction.rejected.type]: (state, action: PayloadAction<string>) => {
        //    state.isLoading = false;
            state.error = action.payload;
          //  message.error(`Products receive error ${action.payload}`);
        },
        [asyncGetProductsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalProducts = count;
            state.products = rows;
        //    message.success('Products received');
        },
        [asyncGetProductsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetProductsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(`Products receive error ${action.payload}`);
        },
        [asyncGetProductAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.product = action.payload;
      //      message.success('Product received');
        },
        [asyncGetProductAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetProductAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(`Product receive error ${action.payload}`);
        },

        [asyncUpdateProductAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.product = action.payload;
            message.success('Product updated');
        },
        [asyncCreateProductAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncCreateProductAction.rejected.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            //action.payload.forEach((item:string)=>{
                message.error(`${state.error}`);
            //})

        },

        [asyncCreateProductAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.product = action.payload;
            message.success('Product created');
        },
        [asyncUpdateProductAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateProductAction.rejected.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(`${action.payload.join(',')}`);
        },
        [asyncDeleteProductAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.products = state.products.filter(item=>item.id !== action.payload.id);
            message.success('Product deleted');
        },
        [asyncDeleteProductAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteProductAction.rejected.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(`${action.payload.join(',')}`);
        },

    }
});

export const {resetProducts,changeRedirect} = productsSlice.actions;
export default productsSlice.reducer;