import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryInfoList from "../../components/molecules/CategoryInfoList/CategoryInfoList";
import {asyncGetAllTransactionsAction} from "../../redux/slices/transactions/transactionsAction";
import Pagination from "../../components/atoms/Pagination/Pagination";
import { useParams } from 'react-router-dom';
import {selectTransactionsList} from "../../selectors/selectors";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";


const Transactions:FC = () => {
    const {isLoading,transactions,totalTransactions,currentPage} = useSelectorEx(state => state.transactions);
    const dispatch = useDispatchEx();

    const dataList: any[] = useSelectorEx(selectTransactionsList)
    const { pageNumber } = useParams();

    useEffect(()=>{
        dispatch(asyncGetAllTransactionsAction({
            page: pageNumber ? Number(pageNumber) : 1,
            limit:10,
            sort:'desc'
        }));
    },[pageNumber])

    const onPagination = (currentPage:number) =>{
    /*    dispatch(asyncGetAllTransactionsAction({
            page: currentPage,
            limit:10,
            sort:'desc'
        }));
       */
    }
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('transactions_pages.title')}  />
                {!isLoading ?
                    <>
                        <CategoryInfoList link={'/orders'} items={dataList}/>
                        <Pagination
                            pageUrl={'/transactions'}
                            totalItems={totalTransactions}
                            itemsPerPage={10}
                            currentPage={pageNumber ? Number(pageNumber) : 1}
                            onPageChange={onPagination}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Transactions;