import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncCreateSettingAction,
    asyncDeleteSettingAction,
    asyncGetSettingAction,
    asyncGetSettingsAction,
    asyncUpdateSettingAction
} from "./settingsAction";
import { ISetting} from "../../../helpers/types";

interface settingsState {
    settings: ISetting[];
    totalSettings:number;
    setting: ISetting | null
    isLoading: boolean;
    error: string
}

const initialState: settingsState = {
    settings: [],
    totalSettings:0,
    setting: null,
    isLoading: false,
    error: ''
};


const settingsSlice = createSlice({
    name:'settingsSlice',
    initialState,
    reducers:{
        resetSettings(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetSettingsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalSettings = count;
            state.settings =rows;
        },
        [asyncGetSettingsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetSettingsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetSettingAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.setting = action.payload;
        },
        [asyncGetSettingAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetSettingAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncUpdateSettingAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.setting = action.payload;
            message.success('Article created');
        },
        [asyncUpdateSettingAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateSettingAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
        [asyncDeleteSettingAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.settings = state.settings.filter(item=>item.id !== action.payload.id);
            message.success('Article deleted');
        },
        [asyncDeleteSettingAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteSettingAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },



        [asyncCreateSettingAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.settings.push(action.payload);
            message.success('Article created');
        },
        [asyncCreateSettingAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncCreateSettingAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
    }
});

export const {resetSettings} = settingsSlice.actions;
export default settingsSlice.reducer;