import instance from "./api";
import {AxiosResponse} from 'axios';
import { IUser} from "../helpers/types";

export default class UsersService {
    static async findAll(): Promise<AxiosResponse<any>> {
        return instance.get<IUser[]>('users' )
    }

    static async findOne(userID:number): Promise<AxiosResponse<any>> {
        return instance.get<IUser[]>(`users/${userID}` )
    }

    static async update(userID:number): Promise<AxiosResponse<any>> {
        return instance.put<IUser[]>(`users/${userID}` )
    }

}