import React, {FC, useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {
    asyncGetOrderAction,

    asyncUpdateOrderAction
} from "../../redux/slices/orders/ordersAction";
import {getDeliverType, getStatusType, getTimeDate} from "../../helpers/scripts";
import OrderProductItem from "../../components/atoms/OrderProductItem/OrderProductItem";
import Preloader from "../../components/atoms/Preloader/Preloader";

import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import ErrorMessage from "../../components/atoms/ErrorMessage/ErrorMessage";
import Select from '../../components/atoms/Select/Select';
import Input from '../../components/atoms/Input/Input';

import './FormOrder.scss'
import TextArea from '../../components/atoms/TextArea/TextArea';
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";

const Order:FC = () => {
    let {orderID} = useParams();

    const dispatch = useDispatchEx();

    const {order, isLoading} = useSelectorEx(state => state.orders);
    const {values,handleChange,errors,touched,handleSubmit,handleBlur,setFieldValue} = useFormik({
        initialValues: {
            client: order?.client as string,
            total: order?.items?.reduce((a:any,b:any)=>{
                return a+(b?.OrderItems?.price*b?.OrderItems?.count)
            },0),
            items: order?.items,
            email:order?.author?.email,
            createdAt:order?.createdAt,
            deliverCity:order?.deliverCity as string,
            deliverOffice:order?.deliverOffice as string,
            deliverRegion:order?.deliverRegion as string,
            deliverType:order?.deliverType,
            payment:order?.payment,
            phone:order?.phone,
            status:order?.status,
            answer: order?.answer,
            comment: order?.comment,
            updatedAt:order?.updatedAt
        },
        enableReinitialize: true,
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (value: any)=>{
        dispatch(asyncUpdateOrderAction({
            id:Number(orderID),
            ...value,
        },));
    };

    useEffect(()=>{
        dispatch(asyncGetOrderAction(Number(orderID)));
    },[]);

    const { t } = useTranslation();

  //  if(!order) return <Preloader/>;
/*    const totalPrice = order?.items?.reduce((a:any,b:any)=>{
        return a+(b?.OrderItems?.price*b?.OrderItems?.count)
    },0)*/


    return (
        <>
            <div className="container">
                {!isLoading || order ?
                    <>
                        <HeaderPanel title={`${t('orders_pages.page.title')} № ${10000+Number(orderID)} ${getTimeDate(order?.createdAt as string)}`} onSave={()=>handleSubmit()} />
                        <form onSubmit={handleSubmit}> <div className="order-form">
                            <div className="order-form__wrapper">
                                <div className="order-form__input">
                                    <Input className={errors.client && touched.client ? "error": ""} value={values.client} onBlur={handleBlur} onChange={handleChange} name={'client'} placeholder={t('orders_pages.fields.client')} />
                                    <ErrorMessage message={errors.client && touched.client && errors.client}/>
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.phone && touched.phone ? "error": ""} value={values.phone} onBlur={handleBlur} onChange={handleChange} name={'phone'} placeholder={t('orders_pages.fields.phone')} />
                                    <ErrorMessage message={errors.phone && touched.phone && errors.phone}/>
                                </div>
                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.deliverType as string}
                                        onChange={(value)=> setFieldValue('deliverType', value)}
                                        className={errors.deliverType && touched.deliverType ? "error": ""}

                                        placeholder={t('orders_pages.fields.deliverType')}
                                        items={[
                                            {
                                                value: 'newpost',
                                                name:getDeliverType('newpost'),
                                            },
                                            {
                                                value: 'meest',
                                                name:getDeliverType('meest'),
                                            },
                                            {
                                                value:'urkpost',
                                                name:getDeliverType('urkpost'),
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.deliverRegion && touched.deliverRegion ? "error": ""} value={values.deliverRegion} onBlur={handleBlur} onChange={handleChange} name={'deliverRegion'} placeholder={t('orders_pages.fields.deliverRegion')} />
                                    <ErrorMessage message={errors.deliverRegion && touched.deliverRegion && errors.deliverRegion}/>
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.deliverCity && touched.deliverCity ? "error": ""} value={values.deliverCity} onBlur={handleBlur} onChange={handleChange} name={'deliverCity'} placeholder={t('orders_pages.fields.deliverCity')} />
                                    <ErrorMessage message={errors.deliverCity && touched.deliverCity && errors.deliverCity}/>
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.deliverOffice && touched.deliverOffice ? "error": ""} value={values.deliverOffice} onBlur={handleBlur} onChange={handleChange} name={'deliverOffice'} placeholder={t('orders_pages.fields.deliverOffice')} />
                                    <ErrorMessage message={errors.deliverOffice && touched.deliverOffice && errors.deliverOffice}/>
                                </div>
                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.payment as string}
                                        onChange={(value)=> setFieldValue('payment', value)}
                                        className={errors.payment && touched.payment ? "error": ""}

                                        placeholder={t('orders_pages.fields.payment.title')}
                                        items={[
                                            {
                                                value: 'online',
                                                name:t('orders_pages.fields.payment.online'),
                                            },
                                            {
                                                value: 'payment',
                                                name:t('orders_pages.fields.payment.payment'),
                                            },
                                            {
                                                value: 'payment_account',
                                                name:'Повна оплата за рахунком',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="order-form__input">
                                    <Select
                                        defaultValue={order?.status?.toString()}
                                        onChange={(value)=> setFieldValue('status', value)}
                                        className={errors.payment && touched.payment ? "error": ""}

                                        placeholder={t('orders_pages.fields.status')}
                                        items={[
                                            {
                                                value: '1',
                                                name: getStatusType(1).name,
                                            },
                                            {
                                                value: '2',
                                                name:getStatusType(2).name,
                                            },
                                            {
                                                value: '3',
                                                name:getStatusType(3).name,
                                            },
                                            {
                                                value: '4',
                                                name:getStatusType(4).name,
                                            },
                                            {
                                                value: '5',
                                                name:getStatusType(5).name,
                                            },
                                            {
                                                value: '6',
                                                name:getStatusType(6).name,
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="order-form__input">
                                    <Input className={errors.email && touched.email ? "error": ""} value={order?.author?.email} onBlur={handleBlur} onChange={handleChange} name={'email'} placeholder={'Email'} />
                                    <ErrorMessage message={errors.email && touched.email && errors.email}/>
                                </div>
                                <div className="order-form__input">
                                    <TextArea className={errors.comment && touched.comment ? "error": ""} value={order?.comment} onBlur={handleBlur} onChange={handleChange} name={'comment'} placeholder={t('orders_pages.fields.comment')} />
                                    <ErrorMessage message={errors.comment && touched.comment && errors.comment}/>
                                </div>
                                <div className="order-form__input">
                                    <TextArea className={errors.answer && touched.answer ? "error": ""} value={values.answer} onBlur={handleBlur} onChange={handleChange} name={'answer'} placeholder={t('orders_pages.fields.answer')} />
                                    <ErrorMessage message={errors.answer && touched.answer && errors.answer}/>
                                </div>
                            </div>
                            <div className="order-form__inner">
                                <div className="order-form__items">
                                    {order?.items ? order?.items?.map((item:any)=>{
                                        return <OrderProductItem key={item.id} image={item.image as string} title={item.title as string} productType={item?.OrderItems?.productType} count={item?.OrderItems?.count} price={item?.OrderItems?.price}/>
                                    }): "No products"}
                                </div>
                            </div>
                        </div></form>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Order;