import instance from "./api";
import {AxiosResponse} from 'axios';
import {IOrder} from "../helpers/types";

export default class OrdersService {
  static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<IOrder>> {
    return instance.get('orders' ,
        {
          params: {
            sort,
            limit,
            page
          }
        })
  }

  static async findOne(orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.get<IOrder>(`orders/${orderID}` )
  }

  static async update(order:any,orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.put<IOrder>(`orders/${orderID}`,order)
  }

  static async delete(orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.delete<IOrder>(`orders/${orderID}`)
  }
}