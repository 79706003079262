import {FC} from 'react';
import './SelectMenu.scss'

type ISelectMenuItem = {
    name: string;
    value: string;
}

interface ISelectMenu{
    currentItem: any;
    items: ISelectMenuItem[];
    onChange?: (event: any) => void;
}

const SelectMenu:FC<ISelectMenu> = (
    {
        currentItem = null,
        items=[],
        onChange=()=>{}
    }
) => {
    return (
        <div className={'select-menu'}>
            {
                items.length
                    ?
                        items.map((item,index) => {
                            return <div key={index} className={`select-menu__item ${currentItem === item.value ? "active" : ""}`}>
                                    <a onClick={()=>onChange(item.value)}>{item.name}</a>
                                </div>
                        })
                        :
                        ""
            }
        </div>
    );
};

export default SelectMenu;