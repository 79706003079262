import {FC} from 'react';

const BagIcon:FC = () => {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.0625 13.791C1.39714 13.791 0.895833 13.6224 0.558594 13.2852C0.221354 12.9525 0.0527344 12.4557 0.0527344 11.7949V4.6377C0.0527344 3.97689 0.221354 3.48014 0.558594 3.14746C0.895833 2.81022 1.39714 2.6416 2.0625 2.6416H9.93066C10.596 2.6416 11.0973 2.81022 11.4346 3.14746C11.7718 3.4847 11.9404 3.98145 11.9404 4.6377V11.7949C11.9404 12.4512 11.7764 12.9479 11.4482 13.2852C11.1247 13.6224 10.6644 13.791 10.0674 13.791H2.0625ZM2.07617 12.957H10.0469C10.3613 12.957 10.6165 12.8545 10.8125 12.6494C11.013 12.4489 11.1133 12.1504 11.1133 11.7539V4.67871C11.1133 4.28223 11.0085 3.98372 10.7988 3.7832C10.5892 3.57812 10.293 3.47559 9.91016 3.47559H2.07617C1.6888 3.47559 1.39258 3.57812 1.1875 3.7832C0.986979 3.98372 0.886719 4.28223 0.886719 4.67871V11.7539C0.886719 12.1504 0.986979 12.4489 1.1875 12.6494C1.39258 12.8545 1.6888 12.957 2.07617 12.957ZM3.43652 2.9082C3.43652 2.42513 3.54818 1.98991 3.77148 1.60254C3.99935 1.21517 4.30697 0.907552 4.69434 0.679688C5.08171 0.451823 5.51465 0.337891 5.99316 0.337891C6.47624 0.337891 6.91146 0.451823 7.29883 0.679688C7.6862 0.907552 7.99154 1.21517 8.21484 1.60254C8.44271 1.98991 8.55664 2.42513 8.55664 2.9082H7.72266C7.72266 2.56641 7.64746 2.26335 7.49707 1.99902C7.35124 1.73014 7.14844 1.51823 6.88867 1.36328C6.62891 1.20833 6.3304 1.13086 5.99316 1.13086C5.66048 1.13086 5.36426 1.20833 5.10449 1.36328C4.84473 1.51823 4.63965 1.73014 4.48926 1.99902C4.34342 2.26335 4.27051 2.56641 4.27051 2.9082H3.43652Z" fill="#052924"/>
        </svg>

    );
};

export default BagIcon;