import React, {ChangeEvent, FC, useRef} from 'react';
import './UploaderImage.scss'
import AddImageIcon from "../icons/AddImageIcon";
import ButtonRect from "../ButtonRect/ButtonRect";
import CloseIcon from "../icons/CloseIcon";

interface IUploaderImage {
    id?: string;
    title?: string;
    name?: string;
    onChange:(e:ChangeEvent<HTMLInputElement>)=>void;
    onDelete?:()=>void;
    defaultValue?: string;
    value?: any | string | null;
}

const UploaderImage:FC<IUploaderImage> = ({title,name,id,onChange,defaultValue='', value, onDelete=()=>{}}) => {
    const inputRef = useRef<any>();
    const onDeleteEx = ()=>{
        onDelete();
        inputRef.current.value = "";
    };
    console.log('value',value, value !== null && value !== undefined,defaultValue)
    const image = value !== null && value !== undefined ? (typeof value === "string" ?  value : URL.createObjectURL(value)) : defaultValue
    //const image = value !== null ? (typeof value === "string" ?  value : URL.createObjectURL(value)) : defaultValue
    return (
        <div className={'uploader-image'}>
            {image ?
                <div className="uploader-image__img">
                    <img src={image} alt=""/>
                    <div className="uploader-image__controls">
                        <ButtonRect onClick={()=>onDeleteEx()} colorType={'red'} name={<CloseIcon/>}/>
                    </div>
                </div>
                :
                <div className="uploader-image__title">
                    <AddImageIcon/>
                    <span>{title}</span>
                </div>

            }

            <input ref={inputRef} id={id} name={name} accept="image/*" type="file" onChange={onChange} />
        </div>
    );
};

export default UploaderImage;