import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {asyncGetUserAction, asyncGetUsersAction} from "./usersAction";


interface usersState {
    users: any[];
    user: any;
    isLoading: boolean;
    error: string
}

const initialState: usersState = {
    users: [],
    user:null,
    isLoading: false,
    error: ''
};


const usersSlice = createSlice({
    name:'usersSlice',
    initialState,
    reducers:{
        resetUsers(state){
            state = initialState;
        },
    },
    extraReducers: {
        [asyncGetUsersAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {user} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.users = action.payload;
        },
        [asyncGetUsersAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncGetUsersAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetUserAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {user} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        [asyncGetUserAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncGetUserAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },


    }
});

export const {resetUsers} = usersSlice.actions;
export default usersSlice.reducer;