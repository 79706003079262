import React, {FC} from 'react';
import PlaceholderIcon from "../icons/PlaceholderIcon";
import Tag from "../Tag/Tag";


interface IOrderProductItem {
    image:string;
    title:string
    count:number
    price:number
    productType?:string;
}

const OrderProductItem:FC<IOrderProductItem> = ({image = 'https://via.placeholder.com/100x100/',title,count,price,productType}) => {
    return (
        <div className={'order-form__item'}>
            <div className="order-form__item-wrapper">
                <div className="order-form__item-image-container">
                    <div className="order-form__item-image">
                        {image ?  <img src={image ? process.env.REACT_APP_URL_PRODUCT_IMG as string+image : image} alt=""/>: <PlaceholderIcon/>}

                    </div>
                    <div className="order-form__item-title">{title} {productType ? `(${productType})` : ""}</div>
                </div>

                <div className="order-form__controls">
                    <div className="order-form__block">{count} x {price}{process.env.REACT_APP_CURRENCY}</div>
                    <div className="order-form__block"> <Tag type={'light-green'}>{price*count}{process.env.REACT_APP_CURRENCY}</Tag></div>
                </div>
            </div>
        </div>
    );
};

export default OrderProductItem;