import { FC, useEffect, useRef, useState } from 'react';
import './MultiSelect.scss';

interface IItem {
    value: string;
    name: string;
}

interface ISelect {
    name?: string;
    placeholder?: string;
    className?: string;
    items: IItem[];
    defaultValue?: string[];
    onChange?: (values: string[]) => void;
    type?: "default" | "search";
}

const MultiSelect: FC<ISelect> = ({ type = 'default', name, placeholder, className, items, defaultValue = [], onChange = () => {} }) => {
    const [open, setOpen] = useState(false);
    const currentDefaultItems = items.filter(item => defaultValue.includes(item.value));
    const [selectedItems, setSelectedItems] = useState<IItem[]>(currentDefaultItems.length ? currentDefaultItems:  items.filter(item =>  defaultValue.includes(item.name)));
    const [filter, setFilter] = useState('');
    const [filteredItems, setFilteredItems] = useState<IItem[]>(items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase())));

    const selectRef = useRef<HTMLDivElement>(null);

    const handleSelect = (item: IItem) => {
        if (!selectedItems.find(selected => selected.value === item.value)) {
            const newSelectedItems = [...selectedItems, item];
            setSelectedItems(newSelectedItems);
            onChange(newSelectedItems.map(i => i.value));
        }
        setOpen(false);
    };

    const handleRemoveItem = (item: IItem) => {
        const newSelectedItems = selectedItems.filter(selected => selected.value !== item.value);
        setSelectedItems(newSelectedItems);
        onChange(newSelectedItems.map(i => i.value));
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);


    return (
        <div ref={selectRef} className={`multi-select ${open ? 'active' : ''} ${className || ''}`}>
            <div className={`multi-select__active`} onClick={type !== 'search' ? () => setOpen(!open) : (!open ? () => setOpen(true) : () => {})}>
                <div className={'multi-select__label'}>{placeholder || 'Обрати'}</div>
                <div className={'multi-select__current'}>
                    {selectedItems.length ?
                        selectedItems.map((item, index) => (
                                <div key={index} className="multi-select__selected-item">
                                    {item.name}
                                    <span className="multi-select__remove" onClick={() => handleRemoveItem(item)}>
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="1.0041" height="14.9907" transform="translate(12.7402 1.83984) rotate(45)" fill="#087D74"/>
                                <rect width="1.0041" height="14.9907" transform="translate(2.14062 2.55005) rotate(-45)" fill="#087D74"/>
                                </svg>
                            </span>
                                </div>
                            ))
                        :
                        'Обрати'}

                </div>
                <div className="multi-select__arrow">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>
            </div>
            {open && (
                <div className="multi-select__items">
                    {filteredItems.map((item, index) => (
                        <div key={index} className={`multi-select__item ${selectedItems.find(i => i.value === item.value) ? 'active' : ''}`} onClick={() => handleSelect(item)}>
                            {item.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelect;
