import React, {FC} from 'react';
import './CategoryTagsList.scss'
import {Link} from "react-router-dom";
import TextAvatar from "../../atoms/TextAvatar/TextAvatar";
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import CloseIcon from "../../atoms/icons/CloseIcon";
import Placeholder from "../../atoms/Placeholder/Placeholder";


interface ICategoryTagsItem{
    id: number;
    title: string;
    list?:any;
    category?:any
}

interface ICategoryTagsList{
    link?:string
    items: ICategoryTagsItem[];
    onDelete?:(id:number)=>void;
}

const CategoryTagsList:FC<ICategoryTagsList> = ({link='',items=[],onDelete=()=>{}}) => {
    return (
        <div className="category-tags-list">
            {items?.length ? items.map(item =>{
                return <div key={item.id} className="category-tags-list__item">
                    <Link to={`${link}/${item.id}`}>
                        <div className="category-tags-list__item-wrapper">
                            <div className="category-tags-list__item-image-container">
                                <div className="category-tags-list__item-id">
                                   # {item.id}
                                </div>
                                <div className="category-tags-list__item-image">
                                    <TextAvatar name={item.title} />
                                </div>
                                <div className="category-tags-list__item-title">{item.title}</div>
                                {item.category ? <div className="category-tags-list__item-category">{item.category}</div> : ""}
                            </div>
                            <div className="category-tags-list__item-details">
                                <div className="category-tags-list__item-status">
                                    {item.list}
                                </div>
                                <div className="category-tags-list__controls">
                                    <ButtonRect onClick={()=>onDelete(item.id)} colorType={'red'} name={<CloseIcon/>}/>
                                </div>
                            </div>
                        </div>
                    </Link>

                </div>
            }) : <Placeholder header={'Ups...'} message={"Results not found"}/>}

        </div>
    );
};

export default CategoryTagsList;