import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryInfoList from "../../components/molecules/CategoryInfoList/CategoryInfoList";
import Pagination from "../../components/atoms/Pagination/Pagination";
import { useParams } from 'react-router-dom';
import {selectBasketsList} from "../../selectors/selectors";
import {asyncGetAllBasketsAction} from "../../redux/slices/baskets/basketsAction";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";


const Baskets:FC = () => {
    const {isLoading,baskets,totalBaskets,currentPage} = useSelectorEx(state => state.baskets);
    const dispatch = useDispatchEx();

    const dataList: any[] = useSelectorEx(selectBasketsList)
    const { pageNumber } = useParams();

    useEffect(()=>{
        dispatch(asyncGetAllBasketsAction({
            page: pageNumber ? Number(pageNumber) : 1,
            limit:10,
            sort:'desc'
        }));
    },[pageNumber])

    const onPagination = (currentPage:number) =>{
    /*    dispatch(asyncGetAllTransactionsAction({
            page: currentPage,
            limit:10,
            sort:'desc'
        }));
       */
    }
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('baskets_pages.title')}  />
                {!isLoading ?
                    <>
                        <CategoryInfoList link={'/baskets'} items={dataList}/>
                        <Pagination
                            pageUrl={'/baskets'}
                            totalItems={totalBaskets}
                            itemsPerPage={10}
                            currentPage={pageNumber ? Number(pageNumber) : 1}
                            onPageChange={onPagination}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Baskets;