import React, {FC, useEffect, useRef, useState} from 'react';
import './UploaderGallery.scss'
import ButtonRect from "../ButtonRect/ButtonRect";
import CloseIcon from "../icons/CloseIcon";
import AddImageIcon from "../icons/AddImageIcon";
interface IUploadList {
    id?: string;
    title?: string;
    name?: string;
    onChange:(e:any[])=>void;
    onDelete:(id:number)=>void;
    defaultValue?: string[];
}

const UploaderGallery:FC<IUploadList> = ({title,name, id, onChange, defaultValue=[],onDelete}) => {
    const inputRef = useRef<any>();
    const [url, setUrl] = useState<string[]>(defaultValue);
    const [files, setFiles] = useState<any[]>([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const onChangeEx = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const imageList:string[] = [];
        const arrayList = Array.from(e.target.files as FileList);
        for(let value of arrayList){
            imageList.push(URL.createObjectURL(value))
        }
        inputRef.current.value = "";
        console.log('inputRef',e.target.files)
        setUrl(prevState => [...prevState,...imageList]);
        setFiles(prevState => [...prevState,...arrayList]);
        onChange([...files,...arrayList]);
    };

    const onRemove = (id:number)=>{
        setFiles(prevState => prevState.filter(item=>item !== prevState[id]));
        setUrl(prevState => prevState.filter(item=>item !== prevState[id]));
        onChange(files.filter(item=>item !== files[id]));
        console.log('onDelete',id)
        onDelete(id)
    };

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      //  e.preventDefault();
        setIsDragOver(true);
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      //  e.preventDefault();  // This is necessary to allow the drop event to fire
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
       // e.preventDefault();
        setIsDragOver(false);
    };
    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        setIsDragOver(false);
       /* e.preventDefault();
        setIsDragOver(false);  // Reset drag over state
        if (e.dataTransfer.files) {
            const fileList: File[] = Array.from(e.dataTransfer.files);
            const imageList: string[] = fileList.map(file => URL.createObjectURL(file));
            setUrl(prev => [...prev, ...imageList]);
            setFiles(prev => [...prev, ...fileList]);
            onChange([...files, ...fileList]);
        }*/
    };

    return (
        <div className={`uploader-gallery ${isDragOver ? ' drop' : ''}`}>
            <div className="uploader-gallery__upload">

                <input
                    onDrop={onDrop}
                    onDragOver={onDragOver}
                                    onDragEnter={onDragEnter}
                                    onDragLeave={onDragLeave} ref={inputRef} id={id} name={name} type="file" multiple={true} onChange={onChangeEx} />
            </div>
            {!url.length ?
                <div className="uploader-gallery__title">
                    <AddImageIcon/>
                    <span>{title}</span>
                </div>
                :
                <div className="uploader-gallery__list">
                    {url.map((item,index)=>{
                    return <div key={index} className="uploader-gallery__img">
                        <img src={item} alt=""/>
                        <div className="uploader-gallery__controls">
                            <ButtonRect className={'btn-rect--small'} onClick={()=>onRemove(index)} colorType={'red'} name={<CloseIcon/>}/>
                        </div>
                    </div>
                    })}
                </div>
            }

        </div>
    );
};


export default UploaderGallery;