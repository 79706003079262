import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {
    asyncCheckAction,
    asyncLoginAction,
    asyncLogoutAction,
    asyncRegistrationGoogleAction,
    asyncSettingsAction
} from "./authAction";
import {message} from "antd";


interface authState {
    user: any;
    isLoading: boolean;
    error: string
}

const initialState: authState = {
    user: null,
    isLoading: false,
    error: ''
};


const authSlice = createSlice({
    name:'authSlice',
    initialState,
    reducers:{
        loadUser(state,action){
            state.user = action.payload;
        },
        logout(state,action){
            state.user = null;
        }
    },
    extraReducers: {
        [asyncRegistrationGoogleAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {user} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        [asyncRegistrationGoogleAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncRegistrationGoogleAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [asyncLoginAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {user} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        [asyncLoginAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncLoginAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [asyncCheckAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {user} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
        },
        [asyncCheckAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncCheckAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = 'Error: Cant auth'// message.error(action.payload);
        },
        [asyncSettingsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.user = action.payload;
            message.success('Settings updated');
        },
        [asyncSettingsAction.pending.type]: (state) => {
            state.isLoading = true;

        },
        [asyncSettingsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
        [asyncLogoutAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.user = null;
            message.success('Logout success');
        },
    }
});

export const {} = authSlice.actions;
export default authSlice.reducer;