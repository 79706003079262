import {RcFile, UploadFile} from "antd/es/upload";
import htmlToDraft from "html-to-draftjs";

import { ContentState, EditorState } from 'draft-js';


export function getTimeDate(dateString: string) {
    const date = new Date(dateString);
    return ("0" + (date.getDate())).slice(-2) + '.' +
        ("0" + (date.getMonth() + 1)).slice(-2) + '.' +
        date.getFullYear() + '/' +
        ("0" + (date.getHours())).slice(-2) + ':' +
        ("0" + (date.getMinutes())).slice(-2);
}

export const onPreviewImage = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
        src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj as RcFile);
            reader.onload = () => resolve(reader.result as string);
        });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
};

export const htmlToDraftBlocks = (html:string) => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
};

export function getDeliverType(type:string) {
    switch (type) {
        case "newpost":
            return "Самовивіз з Нової Пошти";
        case "meest":
            return "Самовивіз з Meest";
        case "urkpost":
            return "Самовивіз з Укрпошти";
    }
    return type
}
export function getPaymentType(type:string) {
    switch (type) {
        case "delivered":
            return "Оплата при отримані";
    }
    return type
}

export function getStatusType(type:number) {
    switch (type) {
        case 1:
            return {
                name: "В обробці",
                color: "#81b6f1"
            };
        case 2:
            return {
                name: "Очікує оплату",
                color: "#7FAAD2"
            };
        case 3:
            return {
                name: "Оплачено",
                color: "#E8B34D"
            };
        case 4:
            return {
                name: "Підтверджено",
                color: "#E8B34D"
            };
        case 5:
            return {
                name: "Виконано",
                color: "#013534"
            };
        case 6:
            return {
                name: "Анульовано",
                color: "#E8684D"
            };
    }
    return {
        name: "Error",
        color: "#e74c3c"
    }
}
export function getRandomColor(name: string | undefined): string {
    const initial = name?.charAt(0).toUpperCase();
    const colors: string[] = [
        '#e8684d',
        '#7faad2',
        '#e8b34d', // purple
        '#0f524e', // deep purple

    ];
    const index: number = initial ? initial.charCodeAt(0) % colors.length : 0;
    return colors[index];
}