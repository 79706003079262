import React, {FC, ReactNode} from 'react';
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import CloseIcon from "../../atoms/icons/CloseIcon";


import './ICategoryImageList.scss'
import PlaceholderIcon from "../../atoms/icons/PlaceholderIcon";
import {Link} from "react-router-dom";

interface ICategoryImageItem{
    id: number,
    title: ReactNode,
    image: string
}

interface ICategoryImageList{
    link?:string
    items: ICategoryImageItem[];
    onDelete?:(id:number)=>void;
}

const CategoryImageList:FC<ICategoryImageList> = ({link='',items=[],onDelete=()=>{}}) => {
    return (
        <div className="category-image-list">
            {items?.length ? items.map(item =>{
                return <div key={item.id} className="category-image-list__item">
                    <Link to={`${link}/${item.id}`}>
                        <div className="category-image-list__item-wrapper">
                            <div className="category-image-list__item-image-container">
                                <div className="category-image-list__item-image">
                                    <div className="category-image-list__item-id">{item.id}</div>
                                    {item.image ?  <img src={item.image} alt=""/>: <PlaceholderIcon/>}

                                </div>
                                <div className="category-image-list__title">{item.title}</div>
                            </div>

                            <div className="category-image-list__controls">
                                <ButtonRect onClick={()=>onDelete(item.id)} colorType={'red'} name={<CloseIcon/>}/>
                            </div>
                        </div>
                    </Link>

                </div>
            }) : "No results found"}

        </div>
    );
};

export default CategoryImageList;