import instance from "./api";
import {AxiosResponse} from 'axios';

export default class StatisticsService {
    static async findOrders(): Promise<AxiosResponse<any>> {
        return instance.get<any[]>('orders/statistics',{} )
    }
    static async findBaskets(): Promise<AxiosResponse<any>> {
        return instance.get<any[]>('basket/statistics',{} )
    }
}