import {FC} from 'react';

const AttachIcon:FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512"
             height="512" x="0" y="0" viewBox="0 0 24 24"
             className="">
            <g>
                <path d="M8.341 22.896c-1.778 0-3.451-.693-4.709-1.951s-1.951-2.931-1.951-4.709c0-1.753.711-3.471 1.951-4.71l9.048-9.048c.887-.886 2.064-1.374 3.316-1.374s2.43.488 3.317 1.374c.886.886 1.373 2.063 1.373 3.317s-.487 2.432-1.373 3.317l-9.059 9.048c-1.02 1.019-2.827 1.021-3.847 0-.506-.505-.797-1.207-.797-1.923s.29-1.417.797-1.924l8.361-8.351a.751.751 0 0 1 1.061 1.061l-8.36 8.35c-.228.227-.357.542-.357.863s.13.635.357.862c.455.455 1.271.456 1.726 0l9.059-9.048c.603-.601.934-1.402.934-2.256s-.332-1.654-.934-2.256c-1.207-1.205-3.306-1.205-4.513 0l-9.049 9.048a5.197 5.197 0 0 0-1.512 3.65c0 1.378.537 2.674 1.512 3.649s2.271 1.512 3.648 1.512a5.197 5.197 0 0 0 3.65-1.512l9.048-9.048a.75.75 0 1 1 1.061 1.061l-9.048 9.048a6.708 6.708 0 0 1-4.711 1.951z" fill="#000000" opacity="1" data-original="#000000" className=""></path>
            </g>
        </svg>
    );
};

export default AttachIcon;