import {FC} from 'react';

const TagIcon:FC = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.27539 12.9746C6.88086 13.3691 6.48047 13.5664 6.07422 13.5664C5.66797 13.5703 5.26367 13.3711 4.86133 12.9688L1.13477 9.24805C0.736328 8.8457 0.537109 8.44141 0.537109 8.03516C0.537109 7.62891 0.736328 7.22852 1.13477 6.83398L6.90625 1.08594C7.03906 0.953125 7.1582 0.857422 7.26367 0.798828C7.36914 0.736328 7.48828 0.697266 7.62109 0.681641C7.75391 0.662109 7.92188 0.652344 8.125 0.652344H10.3574C10.5449 0.652344 10.6992 0.667969 10.8203 0.699219C10.9414 0.730469 11.0527 0.787109 11.1543 0.869141C11.2598 0.947266 11.3867 1.05859 11.5352 1.20312L12.9062 2.57422C13.0547 2.72266 13.168 2.84961 13.2461 2.95508C13.3281 3.06055 13.3828 3.17383 13.4102 3.29492C13.4414 3.41211 13.457 3.56641 13.457 3.75781V5.98438C13.457 6.19141 13.4473 6.36133 13.4277 6.49414C13.4121 6.62305 13.375 6.74023 13.3164 6.8457C13.2578 6.95117 13.1602 7.07227 13.0234 7.20898L7.27539 12.9746ZM6.79492 12.4355L12.5254 6.69922C12.5957 6.62891 12.6484 6.55273 12.6836 6.4707C12.7227 6.38867 12.7422 6.29492 12.7422 6.18945V3.7168C12.7422 3.52539 12.6699 3.35547 12.5254 3.20703L10.9023 1.58984C10.7539 1.44141 10.584 1.36719 10.3926 1.36719H7.92578C7.81641 1.36719 7.7207 1.38672 7.63867 1.42578C7.55664 1.46484 7.48047 1.51953 7.41016 1.58984L1.66211 7.30859C1.42773 7.54297 1.30859 7.78516 1.30469 8.03516C1.30469 8.28125 1.42578 8.52344 1.66797 8.76172L5.3418 12.4355C5.82617 12.9238 6.31055 12.9238 6.79492 12.4355ZM9.69531 5.12891C9.49219 5.12891 9.32227 5.06055 9.18555 4.92383C9.05273 4.7832 8.98633 4.61523 8.98633 4.41992C8.98633 4.2168 9.05273 4.04883 9.18555 3.91602C9.32227 3.7793 9.49219 3.71094 9.69531 3.71094C9.89844 3.71094 10.0664 3.7793 10.1992 3.91602C10.3359 4.04883 10.4043 4.2168 10.4043 4.41992C10.4043 4.61523 10.3359 4.7832 10.1992 4.92383C10.0664 5.06055 9.89844 5.12891 9.69531 5.12891Z" fill="#052924"/>
        </svg>
    );
};

export default TagIcon;