import React, {FC, useEffect} from 'react';

import HeaderPanel from "../components/molecules/HeaderPanel/HeaderPanel";
import PreloaderContent from "../components/atoms/PreloaderContent/PreloaderContent";
import {useDispatchEx, useSelectorEx} from "../hooks/redux";

import StatisticsItems from "../components/molecules/StatisticsItems/StatisticsItems";
import {
    asyncGetBasketsStatisticsAction,
    asyncGetOrdersStatisticsAction
} from "../redux/slices/statistics/statisticsAction";

const Dashboard:FC = () => {
    const dispatch = useDispatchEx();

    useEffect(() => {
        dispatch(asyncGetOrdersStatisticsAction());
        dispatch(asyncGetBasketsStatisticsAction());
    }, []);
    const {isLoading} = useSelectorEx(state => state.statistics)
    return (
        <>
            <>
                <div className="container">
                    <HeaderPanel title={'Статистика'} />
                    {!isLoading ?
                        <>
                            <StatisticsItems/>
                        </>
                        :
                        <PreloaderContent/>
                    }

                </div>
            </>
        </>
    );
};

export default Dashboard;