import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncCreateArticleAction,
    asyncDeleteArticleAction,
    asyncGetArticleAction,
    asyncGetArticlesAction,
    asyncUpdateArticleAction
} from "./articlesAction";
import {IArticle} from "../../../helpers/types";

interface articlesState {
    articles: IArticle[];
    totalArticles:number;
    article: IArticle | null
    isLoading: boolean;
    error: string
}

const initialState: articlesState = {
    articles: [],
    totalArticles:0,
    article: null,
    isLoading: false,
    error: ''
};


const articlesSlice = createSlice({
    name:'articlesSlice',
    initialState,
    reducers:{
        resetArticles(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetArticlesAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalArticles = count;
            state.articles =rows;
        },
        [asyncGetArticlesAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetArticlesAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetArticleAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.article = action.payload;
        },
        [asyncGetArticleAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetArticleAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncUpdateArticleAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.article = action.payload;
            message.success('Article created');
        },
        [asyncUpdateArticleAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateArticleAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
        [asyncDeleteArticleAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.articles = state.articles.filter(item=>item.id !== action.payload.id);
            message.success('Article deleted');
        },
        [asyncDeleteArticleAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteArticleAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },



        [asyncCreateArticleAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.articles.push(action.payload);
            message.success('Article created');
        },
        [asyncCreateArticleAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncCreateArticleAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
    }
});

export const {resetArticles} = articlesSlice.actions;
export default articlesSlice.reducer;