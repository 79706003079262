"use client";
import { FC} from "react";
import './SearchBar.scss';
import {useFormik} from "formik";
import SearchIcon from "../icons/SearchIcon";
import {useDispatchEx} from "../../../hooks/redux";
import {asyncGetSearchProductsAction} from "../../../redux/slices/products/productsAction";
import {useTranslation} from "react-i18next";


interface IInput {
    className?: string;
    type?: "text" | "password" | "email";
    placeholder?: string;
    message?: string | undefined | false | any;
    value?: string;
}

const SearchBar:FC<IInput> = ({className="",type="text",placeholder,value}) => {
    const {errors,touched,values,handleChange,handleBlur,handleSubmit,setFieldValue} = useFormik({
        initialValues: {
            search: '',
        },
        validationSchema:null,
        onSubmit: (value) => formHandle(value),
    });

    const dispatch = useDispatchEx();
    const formHandle = async (values: any)=>{
        const {search} = values;
        dispatch(asyncGetSearchProductsAction({
          //  category: null,
            search: search,
        }));
    };

    const { t } = useTranslation();
    return (
        <div className={`search-bar`}>
            <form onSubmit={handleSubmit}>
                <div className={'search-bar__wrapper'}>
                    <input autoComplete={'off'}  placeholder={''} type={!type ? 'text' : type} name={'search'} onChange={handleChange} onBlur={handleBlur} value={values.search} />
                    <div className={`search-bar__label`}>
                        {t('elements.search')}
                    </div>
                    <div onClick={()=>handleSubmit()} className="search-bar__search">
                        <SearchIcon/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;