import {createAsyncThunk} from "@reduxjs/toolkit";
import OrdersService from "../../../api/OrdersService";
import {IOrder} from "../../../helpers/types";

export const asyncGetOrdersAction = createAsyncThunk(
    'orders/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit} = query;
            let response = await OrdersService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetOrderAction = createAsyncThunk(
    'orders/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await OrdersService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncUpdateOrderAction = createAsyncThunk(
    'orders/update',
    async (order:IOrder, thunkAPI)=>{
        try {
            const {id,deliverCity,deliverRegion,deliverOffice,deliverType,answer,phone,client,status} = order;
         /*   const formData = new FormData();
            formData.append('title', title as string);
            formData.append('content', content as string);
            if(typeof image === "string") formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');
*/
            let response = await OrdersService.update({deliverCity,deliverRegion,deliverOffice,deliverType,answer,phone,client,status}, Number(id));
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteOrderAction = createAsyncThunk(
    'orders/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await OrdersService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);