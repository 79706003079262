import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {ICategory} from "../../../helpers/types";
import {
    asyncCreateCategoryAction,
    asyncDeleteCategoryAction, asyncGetCategoriesAction,
    asyncGetCategoryAction,
    asyncUpdateCategoryAction
} from "./categoriesAction";


interface categoryState {
    categories: ICategory[];
    category: ICategory | null;
    isLoading: boolean;
    isLoadingCategory: boolean;
    error: string
}

const initialState: categoryState = {
    categories: [],
    category: null,
    isLoading: false,
    isLoadingCategory: false,
    error: ''
};


const categoriesSlice = createSlice({
    name:'categoriesSlice',
    initialState,
    reducers:{
        resetCategories(state){
            state = initialState;
        },
    },
    extraReducers: {
        [asyncGetCategoriesAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.categories = action.payload?.sort((a:any, b:any) => a.order - b.order);
        },
        [asyncGetCategoriesAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetCategoriesAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetCategoryAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoadingCategory = false;
            state.error = '';
            state.category = action.payload;
        },
        [asyncGetCategoryAction.pending.type]: (state) => {
            state.isLoadingCategory = true;
        },
        [asyncGetCategoryAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoadingCategory = false;
            state.error = action.payload
        },

        [asyncUpdateCategoryAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoadingCategory = false;
            state.error = '';
            state.category = action.payload;
            message.success('Category updated');
        },
        [asyncUpdateCategoryAction.pending.type]: (state) => {
            state.isLoadingCategory = true;
        },
        [asyncUpdateCategoryAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoadingCategory = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncCreateCategoryAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.categories.push(action.payload);
            message.success('Category created');
        },
        [asyncCreateCategoryAction.pending.type]: (state) => {
            state.isLoadingCategory = true;
        },
        [asyncCreateCategoryAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoadingCategory = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncDeleteCategoryAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.categories = state.categories.filter(item=>item.id !== action.payload.id)
            message.success('Category deleted');
        },
        [asyncDeleteCategoryAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteCategoryAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },

    }
});

export const {resetCategories} = categoriesSlice.actions;
export default categoriesSlice.reducer;