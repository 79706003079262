import instance from "./api";
import {AxiosResponse} from 'axios';
import {ISetting} from "../helpers/types";

export default class SettingsService {
    static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<any>> {
        return instance.get<ISetting[]>('settings',{
            params: {
                sort,
                limit,
                page
            }
        } )
    }

    static async findOne(settingID:number): Promise<AxiosResponse<ISetting>> {
        return instance.get<ISetting>(`settings/${settingID}` )
    }

    static async create(setting:FormData): Promise<AxiosResponse<ISetting>> {
        return instance.post<ISetting>(`settings`,setting)
    }

    static async update(setting:FormData,settingID:number): Promise<AxiosResponse<ISetting>> {
        return instance.put<ISetting>(`settings/${settingID}`,setting)
    }

    static async delete(settingID:number): Promise<AxiosResponse<ISetting>> {
        return instance.delete<ISetting>(`settings/${settingID}`)
    }
}