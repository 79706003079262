import {createAsyncThunk} from "@reduxjs/toolkit";

import StatisticsService from "../../../api/StatisticsService";

export const asyncGetOrdersStatisticsAction = createAsyncThunk(
    'statistics/orders',
    async (_, thunkAPI)=>{
        try {
            //asyncGetOrdersStatisticsAction
            let response = await StatisticsService.findOrders();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetBasketsStatisticsAction = createAsyncThunk(
    'statistics/baskets',
    async (_, thunkAPI)=>{
        try {
            //asyncGetOrdersStatisticsAction
            let response = await StatisticsService.findBaskets();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);