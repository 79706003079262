import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {INotification} from "../../../helpers/types";
import {asyncGetAllNotificationsAction, asyncGetCountNotificationsAction} from "./notificationsAction";

interface notificationsState {
    notifications: INotification[];
    totalNotifications:number;
    currentNotification: INotification | null;
    unreadNotifications:number;
    currentPage:number;
    isLoading: boolean;
    error: string
}

const initialState: notificationsState = {
    notifications: [],
    totalNotifications: 0,
    unreadNotifications: 0,
    currentNotification: null,
    currentPage: 1,
    isLoading: false,
    error: ''
};


const notificationsSlice = createSlice({
    name:'notificationsState',
    initialState,
    reducers:{
        addNotification(state,action){
            state.notifications = [action.payload,...state.notifications];
        },
        increaseCountNotification(state,action){
            state.unreadNotifications = state.unreadNotifications + (action.payload ? action.payload : 1);
        },
        setCurrentNotification(state,action){
            state.currentNotification = action.payload;
        },
        setCurrentPageNotifications(state,action){
            state.currentPage = action.payload;
        },
        resetArticles(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetAllNotificationsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalNotifications = count;
            state.notifications =rows;
        },
        [asyncGetAllNotificationsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetAllNotificationsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [asyncGetCountNotificationsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.unreadNotifications = count;
        },
        [asyncGetCountNotificationsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetCountNotificationsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

    }
});

export const {addNotification,resetArticles,setCurrentNotification,setCurrentPageNotifications,increaseCountNotification} = notificationsSlice.actions;
export default notificationsSlice.reducer;