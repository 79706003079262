import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {asyncGetOrdersAction} from "../../redux/slices/orders/ordersAction";
import {getStatusType, getTimeDate} from "../../helpers/scripts";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryInfoList from "../../components/molecules/CategoryInfoList/CategoryInfoList";
import Pagination from "../../components/atoms/Pagination/Pagination";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Orders:FC = () => {
    const {isLoading,orders,totalOrders} = useSelectorEx(state => state.orders);
    const dispatch = useDispatchEx();

    const dataList: any[] = orders.map((item:any)=>{
        return {
           // key: item.id,
            id: item.id,
            title: item?.author?.email,
            date: getTimeDate(item.createdAt as string),
            status: getStatusType(item.status as number),
            total: item.items?.reduce((a:any,b:any)=>{
                return a+(b?.OrderItems?.price*b?.OrderItems?.count)
            },0),
        }
    }) as [];

    const { pageNumber } = useParams();
    useEffect(()=>{
        dispatch(asyncGetOrdersAction({
            page: pageNumber ? Number(pageNumber) : 1,
            limit:10,
            sort:'desc'
        }));
    },[pageNumber])
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('orders_pages.title')}  />
                {!isLoading ?
                    <>
                        <CategoryInfoList link={'/orders'} items={dataList}/>
                        <Pagination
                            pageUrl={'/orders'}
                            totalItems={totalOrders}
                            itemsPerPage={10}
                            currentPage={pageNumber ? Number(pageNumber) : 1}
                            onPageChange={()=>{}}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Orders;