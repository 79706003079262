import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {asyncDeleteAttributeAction, asyncGetAttributesAction} from "../../redux/slices/attributes/attributesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryTagsList from "../../components/molecules/CategoryTagsList/CategoryTagsList";
import Tag from "../../components/atoms/Tag/Tag";
import {getRandomColor, getTimeDate} from "../../helpers/scripts";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";


const Attributes:FC = () => {

    const dispatch = useDispatchEx();
    useEffect(()=>{
        dispatch(asyncGetAttributesAction(null));
    },[]);

    const {isLoading,attributes} = useSelectorEx(state => state.attributes)
    const data: any[] = attributes.map((item:any)=>{
        return {
            id: item.id,
            title: item.name as string,
            list: item?.values?.split(',').map((value:string)=>{
                return <Tag type={'status'} color={getRandomColor(value)}>{value}</Tag>
            }),
            category: item?.category?.title ? <Tag color={getRandomColor(item?.category?.title)}>{item?.category?.title}</Tag>: <Tag color={getRandomColor("Без категорії")}>{"Без категорії"}</Tag>
        }
    });

    const onDelete = (id:number)=>{
        dispatch(asyncDeleteAttributeAction(id));
    }
    const { t } = useTranslation();
   // if(isLoading) return <Preloader/>;
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('attributes_pages.title')} addUrl={'/attributes/create'} />
                {!isLoading ?
                    <>
                        <CategoryTagsList onDelete={onDelete} link={'/attributes'} items={data}/>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Attributes;