import React, {FC, ReactNode, useEffect, useState} from 'react';
import Sidebar from "../../components/molecules/Sidebar/Sidebar";
import {Outlet} from "react-router-dom";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {
    addNotification,
    increaseCountNotification,
    setCurrentNotification
} from "../../redux/slices/notifications/notificationsSlice";
import {io} from "socket.io-client";
import FlashNotification from "../../components/atoms/FlashNotification/FlashNotification";
import {getRandomColor} from "../../helpers/scripts";
import {selectNotification} from "../../selectors/selectors";
import {asyncGetCountNotificationsAction} from "../../redux/slices/notifications/notificationsAction";

interface ILayout {
    children?: ReactNode;
    className?:string;
}

const Layout:FC<ILayout> = ({children,className}) => {
    const dispatch = useDispatchEx();
    const currentNotification = useSelectorEx(selectNotification);
    const [notificationList, setNotificationList] = useState<any>([]);
    const handleClose = (id:any) => {
        setNotificationList((prevList:any) => prevList.filter((notification:any) => notification.id !== id));
        dispatch(setCurrentNotification(null))
    };

    useEffect(() => {
        if(currentNotification){
            setNotificationList((prevList:any) => {
                const newList = [currentNotification, ...prevList];
                return newList.slice(0, 3); // Keep only the latest 3 notifications
            });
        }

    }, [currentNotification]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const socket = io('wss://api.vdoma.rfuler.site', {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        socket.on('receiveNotification', (data) => {
            dispatch(increaseCountNotification(null));
            dispatch(setCurrentNotification(data));
            dispatch(addNotification(data))
        });

        socket.on('connect_error', (err) => {
            //  setError('Connection error: ' + err.message);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        dispatch(asyncGetCountNotificationsAction());
    }, []);
    return (
        <>
            <div className="flash-notification__list">
                {notificationList.map((notification:any) => (
                    <FlashNotification
                        key={notification.id}
                        status={{
                            name: `${notification.type}`,
                            color: getRandomColor(notification.type),
                        }}
                        message={notification.title}
                        onClose={() => handleClose(notification.id)}
                    />
                ))}
            </div>

            <div className="wrap">
                <Sidebar/>
                <section className={`page ${className}`}>
                    <div className="content">
                        <Outlet/>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Layout;