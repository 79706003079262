import { Route, Routes } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import Categories from "./pages/Categories/Categories";
import Products from "./pages/Products/Products";

import Orders from './pages/Orders/Orders';
import Attributes from "./pages/Attributes/Attributes";
import 'antd/dist/antd.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './styles/index.scss';
import User from "./pages/Users/User";
import Category from "./pages/Categories/Category";
import Product from "./pages/Products/Product";

import Order from "./pages/Orders/Order";
import Attribute from "./pages/Attributes/Attribute";
import Login from './pages/Login';
import RequireAuth from "./hoc/RequireAuth";
import MySettings from "./pages/MySettings";
import CreateAttribute from "./pages/Attributes/CreateAttribute";
import CreateCategory from './pages/Categories/CreateCategory';
import CreateProduct from './pages/Products/CreateProduct';
import Registration from "./pages/Registration";

import InfoAttribute from "./pages/InfoAttributes/InfoAttribute";
import CreateInfoAttribute from "./pages/InfoAttributes/CreateInfoAttribute";
import InfoAttributes from "./pages/InfoAttributes/InfoAttributes";
import Layout from "./pages/layouts/Layout";
import Transactions from "./pages/Transactions/Transactions";
import Baskets from "./pages/Baskets/Baskets";
import Basket from "./pages/Baskets/Basket";
import './i18n';
import Notifications from "./pages/Notifications/Notifications";
import Users from "./pages/Users/Users";
import Supports from "./pages/Supports/Supports";

function App() {
  return (
    <>
        <Routes>
            {/*public routes*/}
            <Route path="/login" element={<Login/>}/>
            <Route path="/registration" element={<Registration/>}/>
            {/*protected routes*/}
            <Route element={<RequireAuth/>}>
                <Route element={<Layout/>}>
                <Route path="/" element={<Dashboard/>}/>
                <Route path="/users">
                    <Route path=":userID" element={<User/>} />
                    <Route path="page/:pageNumber" element={<Users/>} />
                    <Route index element={<Users/>} />
                </Route>
                <Route path="/categories">
                    <Route path=":categoryID" element={<Category/>} />
                    <Route path="create" element={<CreateCategory/>} />
                    <Route index element={<Categories/>} />
                </Route>
                <Route path="/products">
                    <Route path=":productID" element={<Product/>} />
                    <Route path="create" element={<CreateProduct/>} />
                    <Route path="page/:pageNumber" element={<Products/>} />
                    <Route index element={<Products/>} />
                </Route>
{/*                <Route path="/articles">
                    <Route path=":articleID" element={<Article/>} />
                    <Route path="create" element={<CreateArticle/>} />
                    <Route index element={<Articles/>} />
                </Route>*/}
                <Route path="/orders">
                    <Route path=":orderID" element={<Order/>} />
                    <Route path="page/:pageNumber" element={<Orders/>} />
                    <Route index element={<Orders/>} />
                </Route>
                    <Route path="/baskets">
                      <Route path=":basketID" element={<Basket/>} />
                        <Route path="page/:pageNumber" element={<Baskets/>} />
                        <Route index element={<Baskets/>} />
                    </Route>
                <Route path="/transactions">
                    <Route path=":transactionID" element={<Order/>} />
                    <Route path="page/:pageNumber" element={<Transactions/>} />
                    <Route index element={<Transactions/>} />
                </Route>
                <Route path="/attributes">
                    <Route path=":attributeID" element={<Attribute/>} />
                    <Route path="create" element={<CreateAttribute/>} />
                    <Route index element={<Attributes/>} />
                </Route>
                <Route path="/infoAttributes">
                    <Route path=":attributeID" element={<InfoAttribute/>} />
                    <Route path="create" element={<CreateInfoAttribute/>} />
                    <Route index element={<InfoAttributes/>} />
                </Route>
                <Route path="/notifications">
                    <Route path="page/:pageNumber" element={<Notifications/>} />
                    <Route index element={<Notifications/>} />
                </Route>

                <Route path="/supports">
                    <Route path="*" element={<Supports/>} />
                    <Route path="page/:pageNumber" element={<Supports/>} />
                    <Route index element={<Supports/>} />
                </Route>

{/*                <Route path="/settings">
                    <Route path=":settingID" element={<Setting/>} />
                    <Route path="create" element={<CreateSetting/>} />
                    <Route index element={<Settings/>} />
                </Route>*/}
                <Route path="/mysettings" element={<MySettings/>}/>
                <Route path="/logout" element={<Dashboard/>}/>
                </Route>
            </Route>

            <Route path="*" element={<Dashboard/>} />
        </Routes>
    </>
  );
}

export default App;
