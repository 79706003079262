import React, {useEffect} from 'react';

import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {
    asyncDeleteInfoAttributeAction,
    asyncGetInfoAttributesAction
} from "../../redux/slices/infoAttributes/infoAttributesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryTagsList from "../../components/molecules/CategoryTagsList/CategoryTagsList";
import {getRandomColor} from "../../helpers/scripts";
import Tag from "../../components/atoms/Tag/Tag";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";


const InfoAttributes = () => {

    const dispatch = useDispatchEx();
    useEffect(()=>{
        dispatch(asyncGetInfoAttributesAction(null));
    },[]);

    const {isLoading,infoAttributes} = useSelectorEx(state => state.infoAttributes)
    const data: any[] = infoAttributes.map((item:any)=>{
        return {
            key: item.id.toString(),
            id: item.id,
            title: item.name as string,
            list: item?.values?.split(',').map((value:string)=>{
                return <Tag type={'status'} color={getRandomColor(value)}>{value}</Tag>
            }),
            category: item?.category?.title ? <Tag color={getRandomColor(item?.category?.title)}>{item?.category?.title}</Tag>: <Tag color={getRandomColor("Без категорії")}>{"Без категорії"}</Tag>
        }
    });

    const onDelete = (id:number)=>{
        dispatch(asyncDeleteInfoAttributeAction(id));
    }
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('info_attributes_pages.title')} addUrl={'/infoAttributes/create'} />
                {!isLoading ?
                    <>
                        <CategoryTagsList onDelete={onDelete} link={'/infoAttributes'} items={data}/>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default InfoAttributes;