import {createAsyncThunk} from "@reduxjs/toolkit";
import ArticlesService from "../../../api/ArticlesService";
import {IArticle} from "../../../helpers/types";

export const asyncGetArticlesAction = createAsyncThunk(
    'articles/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit} = query;
            let response = await ArticlesService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetArticleAction = createAsyncThunk(
    'articles/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await ArticlesService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateArticleAction = createAsyncThunk(
    'articles/create',
    async (article:IArticle, thunkAPI)=>{
        try {
            const {title,content,image} = article;

            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('content', content as string);
            if(image === '') formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');

            let response = await ArticlesService.create(formData);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateArticleAction = createAsyncThunk(
    'articles/update',
    async (article:IArticle, thunkAPI)=>{
        try {
            const {id,title,content,image} = article;
            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('content', content as string);
            if(typeof image === "string") formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');

            let response = await ArticlesService.update(formData, id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteArticleAction = createAsyncThunk(
    'articles/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await ArticlesService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);