import {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import Pagination from "../../components/atoms/Pagination/Pagination";
import { useParams } from 'react-router-dom';
import {selectNotificationsList} from "../../selectors/selectors";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";
import {
    asyncGetAllNotificationsAction,
    asyncGetCountNotificationsAction
} from "../../redux/slices/notifications/notificationsAction";
import CategoryNotificationsList from "../../components/molecules/CategoryNotificationsList/CategoryNotificationsList";


const Notifications:FC = () => {
    const {isLoading,notifications,totalNotifications,currentPage} = useSelectorEx(state => state.notifications);
    const dispatch = useDispatchEx();

    const dataList: any[] = useSelectorEx(selectNotificationsList)
    const { pageNumber } = useParams();

    useEffect(()=>{
        dispatch(asyncGetAllNotificationsAction({
            page: pageNumber ? Number(pageNumber) : 1,
            limit:10,
            sort:'desc'
        }));
    },[pageNumber])

    const onPagination = (currentPage:number) =>{
    /*    dispatch(asyncGetAllTransactionsAction({
            page: currentPage,
            limit:10,
            sort:'desc'
        }));
       */
    }
    useEffect(() => {
        dispatch(asyncGetCountNotificationsAction());
    }, []);
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('notifications_pages.title')}  />
                {!isLoading ?
                    <>
                        <CategoryNotificationsList items={dataList}/>
                        <Pagination
                            pageUrl={'/notifications'}
                            totalItems={totalNotifications}
                            itemsPerPage={10}
                            currentPage={pageNumber ? Number(pageNumber) : 1}
                            onPageChange={onPagination}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Notifications;