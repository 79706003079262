import instance  from "./api";
import {AxiosResponse} from 'axios';
import {IArticle} from "../helpers/types";

export default class BasketService {
  static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<any>> {
    return instance.get('basket', {
            params: {
                  sort,
                  limit,
                  page
            }
    })
  }

  static async findOne(basketID:number): Promise<AxiosResponse<any>> {
    return instance.get(`basket/${basketID}` )
  }

  static async replaceBasket(products:string): Promise<AxiosResponse<any[]>> {
    return instance.post('basket/load',{
      products
    } )
  }

  static async addToBasket(basket:any): Promise<AxiosResponse<any>> {
    return instance.post(`basket`,{
      ...basket
    } )
  }
  static async updateToBasket(basket:any): Promise<AxiosResponse<any>> {
    return instance.put(`basket`,{
      ...basket
    } )
  }
  static async deleteFromBasket(id: number): Promise<AxiosResponse<any>> {
    return instance.delete(`basket/${id}`);
  }
}