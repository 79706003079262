import {createAsyncThunk} from "@reduxjs/toolkit";

import TransactionsService from "../../../api/TransactionsService";

export const asyncGetAllTransactionsAction = createAsyncThunk(
    'auth/getAllTransactions',
    async (query:any, thunkAPI)=>{
        try {
            const {page,limit,sort } = query;
            let response = await TransactionsService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
