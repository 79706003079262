import instance from "./api";
import {AxiosResponse} from 'axios';
import { ITransaction } from "../helpers/types";

export default class NotificationsService {
  static async findAll(sort?:string,limit?:number,page?:number): Promise<AxiosResponse<ITransaction>> {
    return instance.get('notifications' ,
        {
          params: {
            sort,
            limit,
            page
          }
        })
  }

    static async getCount(): Promise<AxiosResponse<ITransaction>> {
        return instance.get('notifications/count' )
    }

/*  static async findOne(orderID:number): Promise<AxiosResponse<ITransaction>> {
    return instance.get<ITransaction>(`notifications/${orderID}` )
  }*/

/*  static async update(order:any,orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.put<IOrder>(`transactions/${orderID}`,order)
  }

  static async delete(orderID:number): Promise<AxiosResponse<IOrder>> {
    return instance.delete<IOrder>(`transactions/${orderID}`)
  }*/
}