"use client";
import React, {FC,ReactNode, useEffect, useState} from 'react';
import SupportDialogsItem from "../../molecules/SupportDialogsItem/SupportDialogsItem";
import './SupportLayout.scss'
import TextArea from "../../atoms/TextArea/TextArea";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {asyncGetSupportsAction} from "../../../redux/slices/supports/supportsAction";
import {getTimeDate} from "../../../helpers/scripts";
import {Link} from "react-router-dom";

interface ILayout {
    children: ReactNode;
}

interface ISupportDialog {
    name: string;
    message: string;
    date: string;
}

const SupportLayout:FC<ILayout> = ({children}) => {

    const dispatch = useDispatchEx()

    useEffect(() => {
        dispatch(asyncGetSupportsAction({}))
    }, []);

    const {dialogs} = useSelectorEx(state => state.supports)
    const {user} = useSelectorEx(state => state.auth)
    return (
        <>
            <div className="supports__wrapper">
                <div className="supports__sidebar">
                    <div className="supports-dialogs">
                        {dialogs.map((dialog, index) => (
                            <Link key={index} to={`/supports/${dialog.userId}`}>
                                <SupportDialogsItem
                                    key={index}
                                    name={dialog?.author?.email ? dialog?.author?.email : "" }
                                    message={dialog.message}
                                    isRead={dialog?.isRead}
                                    count={dialog?.count}
                                    date={getTimeDate(dialog.createdAt)}
                                />

                            </Link>
                        ))}
                    </div>
                </div>
                <div className="supports__messages-wrapper">
                    {children}

                </div>

            </div>
        </>
    );
};

export default SupportLayout;