import React, {FC, useEffect} from 'react';

import './StatisticsItems.scss'
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";

const StatisticsItems:FC = () => {


    const {ordersStatistics,basketsStatistics} = useSelectorEx(state => state.statistics)
    return (
        <div className="statistics-items">
                <div className="statistics-item">
                    <div className="statistics-item__title">Дохід за день</div>
                    <div className="statistics-item__content">
                        {ordersStatistics?.day} грн.
                    </div>
                </div>

                <div className="statistics-item">
                    <div className="statistics-item__title">Дохід за тиждень</div>
                    <div className="statistics-item__content">
                        {ordersStatistics?.week} грн.
                    </div>
                </div>

                <div className="statistics-item">
                    <div className="statistics-item__title">Дохід за місяць</div>
                    <div className="statistics-item__content">
                        {ordersStatistics?.month} грн.
                    </div>
                </div>

                <div className="statistics-item">
                    <div className="statistics-item__title">Потенційний дохід в кошиках</div>
                    <div className="statistics-item__content">
                        {basketsStatistics?.totalPrice} грн. ({basketsStatistics?.totalCount} шт.)
                    </div>
                </div>
        </div>
    );
};

export default StatisticsItems;