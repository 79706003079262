import {FC, ReactNode} from 'react';
import './CategoryNotificationsList.scss'
import Tag from "../../atoms/Tag/Tag";
import Placeholder from "../../atoms/Placeholder/Placeholder";

interface ICategoryItem{
    keyID?:any
    id: number;
    title: string;
    content?: string;
    date?: string;
    status?:any;
    type?: string;
}

interface ICategoryNotificationsList{
    items: ICategoryItem[];
}

const CategoryNotificationsList:FC<ICategoryNotificationsList> = ({items=[]}) => {
    return (
        <div className="category-notifications-list">
            {items?.length ? items.map((item,index) =>{
                return <div key={index} className="category-notifications-list__item">
                        <div className="category-notifications-list__item-wrapper">
                            <div className="category-notifications-list__item-image-container">
                                <div className="category-info-list__item-status">
                                    <Tag type={'status'} color={item.status.color}>{item.status.name}</Tag>
                                </div>
                                <div className="category-notifications-list__item-image">
                                   {/* <TextAvatar name={item.title} />*/}
                                </div>
                                <div className="category-notifications-list__item-title">{item.title}</div>
                            </div>
                            <div className="category-notifications-list__item-details">

                                {item?.date ? <div className="category-notifications-list__item-date">
                                    {item.date}
                                </div> : ""}
                            </div>
                        </div>
                </div>
            }) : <Placeholder header={'Ups...'} message={"Results not found"}/>}

        </div>
    );
};

export default CategoryNotificationsList;