import {FC} from 'react';

const AddImageIcon:FC = () => {
    return (
        <svg width="43" height="40" viewBox="0 0 43 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.2526 23.75V29.5H43.0026V33.3333H37.2526V39.0833H33.4193V33.3333H27.6693V29.5H33.4193V23.75H37.2526ZM37.2679 0.75C38.3183 0.75 39.1693 1.60292 39.1693 2.65325V20.5722C37.9381 20.1373 36.6417 19.9156 35.3359 19.9167V4.58333H4.66927L4.67119 31.4167L22.4809 13.6051C22.8104 13.2744 23.2495 13.0756 23.7154 13.0459C24.1813 13.0163 24.642 13.1579 25.0109 13.4441L25.191 13.607L31.9856 20.4112C30.4995 20.864 29.1205 21.6131 27.9318 22.6133C26.743 23.6136 25.7691 24.8442 25.0688 26.2309C24.3685 27.6177 23.9564 29.132 23.8571 30.6823C23.7579 32.2327 23.9737 33.7872 24.4914 35.2519L2.73727 35.25C2.23283 35.2495 1.74922 35.0487 1.39271 34.6919C1.03619 34.335 0.835937 33.8512 0.835938 33.3467V2.65325C0.839445 2.14989 1.04083 1.66812 1.39659 1.312C1.75234 0.955884 2.23392 0.754015 2.73727 0.75H37.2679ZM12.3359 8.41667C13.3526 8.41667 14.3276 8.82053 15.0465 9.53942C15.7654 10.2583 16.1693 11.2333 16.1693 12.25C16.1693 13.2667 15.7654 14.2417 15.0465 14.9606C14.3276 15.6795 13.3526 16.0833 12.3359 16.0833C11.3193 16.0833 10.3443 15.6795 9.62536 14.9606C8.90647 14.2417 8.50261 13.2667 8.50261 12.25C8.50261 11.2333 8.90647 10.2583 9.62536 9.53942C10.3443 8.82053 11.3193 8.41667 12.3359 8.41667Z" fill="#0C483E"/>
        </svg>
    );
};

export default AddImageIcon;