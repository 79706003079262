import {ChangeEvent,FocusEvent, FC} from 'react';
import './Input.scss'
interface IInput {
    className?: string;
    classError?: string;
    name: string;
    type?: "text" | "password" | "email" | "hex";
    placeholder: string;
    message?: string | undefined | false | any;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    value?: string;
}
const Input:FC<IInput> = ({name,className,classError,type="text",placeholder,onBlur,onChange,value,message}) => {
    return (
        <div className={`input ${className ? className: ""}`}>
            <label>
                <input placeholder={' '} type={type === 'hex' ? 'text' : type} onChange={onChange} onBlur={onBlur} name={name} value={value} />
                <span className="input__label">{placeholder}</span>
                {type === 'hex' ? <div className="input__hex" style={{
                    background: value
                }}></div>: ""}
            </label>
        </div>
    );
};

export default Input;