import {createAsyncThunk} from "@reduxjs/toolkit";
import CategoriesService from "../../../api/CategoriesService";
import {IAttribute, ICategory} from "../../../helpers/types";

export const asyncGetCategoriesAction = createAsyncThunk(
    'categories/all',
    async (_, thunkAPI)=>{
        try {
            let response = await CategoriesService.findAll();
            return response.data.reverse();
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetCategoryAction = createAsyncThunk(
    'categories/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await CategoriesService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateCategoryAction = createAsyncThunk(
    'categories/create',
    async (category:ICategory, thunkAPI)=>{
        try {
            const {title,description,image,isShow,section_title,order,sub_category} = category;

            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('description', description as string);
            formData.append('section_title', section_title as string);
            formData.append('isShow', isShow as any);
            formData.append('order', order as any);
            formData.append('sub_category', sub_category as any);
            if(typeof image === "string") formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');

            let response = await CategoriesService.create(formData);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateCategoryAction = createAsyncThunk(
    'categories/update',
    async (category:ICategory, thunkAPI)=>{
        try {
            const {id,title,description,image,isShow,section_title,order,sub_category} = category;
            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('description', description as string);
            formData.append('section_title', section_title as string);
            formData.append('isShow', isShow as any);
            formData.append('order', order as any);
            formData.append('sub_category', sub_category as any);

            if(typeof image === "string") formData.append('image', image as string);
            else formData.append('image', image as File, 'image.jpg');



            let response = await CategoriesService.update(formData, id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteCategoryAction = createAsyncThunk(
    'categories/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await CategoriesService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
