import React, {FC, ReactNode, useState} from 'react';
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import PlusIcon from "../../atoms/icons/PlusIcon";
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../atoms/ErrorMessage/ErrorMessage";
import CloseIcon from "../../atoms/icons/CloseIcon";

import './Repeater.scss';

interface IOptionItem{
    placeholder: string;
    name: string;
    type: 'input' | 'textarea' | "inputHex";
    onChange: ()=>void;
    onBlur: ()=>void;
    value:string;
}

interface IRepeater{
    name:string;
    title?:ReactNode;
    subtitle?:string;
    onAddItem?:()=>void;
    onDeleteItem?:(id:number)=>void;
    handleBlur?:()=>void;
    handleChange?:()=>void;
    items: any[];
    values:any;
    errors:any;
    touched:any;
}

const Repeater:FC<IRepeater> = ({name,
                                    title,
                                    items=[],
                                    onAddItem=()=>{},
                                    onDeleteItem=()=>{},
                                    handleChange=()=>{},
                                    handleBlur=()=>{},
                                    values,
                                    errors,
                                    touched,
                                    subtitle=''
}) => {
    const [open,setOpen] = useState<boolean>(false);
    const onAddItemEx = ()=>{
        setOpen(true);
        onAddItem();
    }
    return (
        <div className={`repeater-type-product ${open ? "active": ""}`}>
            <div className="repeater-type-product__header">
                <div className="repeater-type-product__title" onClick={()=>setOpen(!open)}><span>{title}</span>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>
                <div className="repeater-type-product__button">
                    <ButtonRect onClick={onAddItemEx} name={<PlusIcon/>} />
                </div>
            </div>

            <div className="repeater-type-product__wrapper">
                <div className="repeater-type-product__items">
                    {items.map(((item,index)=>{
                        return  <div key={index} className="repeater-type-product__item">
                            <div className="repeater-type-product__item-inner">
                                <div className="repeater-type-product__item-inputs">
                                    <div className="repeater-type-product__item-input">
                                        <Input className={errors && touched ? "error": ""} value={values[index]} onBlur={handleBlur} onChange={handleChange} name={`${name}[${index}]`} placeholder={subtitle} />
                                    </div>
                                </div>
                                <div className="repeater-type-product__item-button">
                                    <ButtonRect onClick={()=>onDeleteItem(index)} colorType={'red'} name={<CloseIcon/>}/>
                                </div>
                            </div>
                        </div>
                    }))}

                </div>
            </div>

        </div>
    );
};

export default Repeater;