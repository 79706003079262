import {FC, useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import {
    asyncGetInfoAttributeAction,
    asyncUpdateInfoAttributeAction
} from "../../redux/slices/infoAttributes/infoAttributesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormInfoAttribute from "../../components/molecules/FormInfoAttribute/FormInfoAttribute";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";

const InfoAttribute:FC = () => {
    let {attributeID} = useParams();

    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            name: '',
            values: [],
            categoryId: null,
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
        const changedValues = {
            ...values,
            values: values.values.join(',')
        }
        dispatch(asyncUpdateInfoAttributeAction({id:Number(attributeID), ...changedValues}));
    };
    useEffect(()=>{
        dispatch(asyncGetInfoAttributeAction(Number(attributeID)));
    },[attributeID]);

    const {isLoading,infoAttribute} = useSelectorEx(state => state.infoAttributes)
    useEffect(()=>{
        if(infoAttribute){
            config.setFieldValue('name',infoAttribute?.name);
            config.setFieldValue('values',infoAttribute?.values?.split(','));
            config.setFieldValue('categoryId',infoAttribute?.categoryId);
        }
    },[infoAttribute]);

   // if(isLoading) return <Preloader/>;
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('info_attributes_pages.page.title')} onSave={()=>config.handleSubmit()}  />
                {!isLoading ?
                    <>
                        <FormInfoAttribute
                            title={''}
                            config={config}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }
            </div>
        </>
    );
};

export default InfoAttribute;