import React, {FC, ReactNode, useState} from 'react';
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import PlusIcon from "../../atoms/icons/PlusIcon";
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../old/atoms/ErrorMessage/ErrorMessage";
import CloseIcon from "../../atoms/icons/CloseIcon";

import './RepeaterInfo.scss'
import TextArea from '../../atoms/TextArea/TextArea';


interface IRepeater{
    name:string;
    title?:ReactNode;
    handleBlur?:()=>void;
    handleChange?:(test?:any)=>void;
    items: any[];
    values:any;
    errors:any;
    touched:any;
}

const RepeaterInfo:FC<IRepeater> = ({name,
                                    title,
                                    items=[],
                                    handleChange=()=>{},
                                    handleBlur=()=>{},
                                    values,
                                    errors,
                                    touched
}) => {
    const [open,setOpen] = useState<boolean>(false);
    return (
        <div className={`repeater-info ${open ? "active": ""}`}>
            <div className="repeater-info__header">
                <div className="repeater-info__title" onClick={()=>setOpen(!open)}><span>{title}</span>
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.5 1L6.5 6L1.5 1" stroke="#C3C4C5" strokeWidth="1.06667" strokeLinecap="square"/>
                    </svg>
                </div>

            </div>

            <div className="repeater-info__wrapper">
                <div className="repeater-info__items">
                    {items.map(((item,index)=>{
                        return  <div key={index} className="repeater-info__item">
                            <div className="repeater-info__item-select">
                                <TextArea className={errors && touched ? "error": ""} value={values[index]?.values} onBlur={handleBlur} onChange={handleChange} name={`${name}[${index}].values`} placeholder={item} />
                            </div>
                        </div>
                    }))}

                </div>
            </div>

        </div>
    );
};

export default RepeaterInfo;