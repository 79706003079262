import {createAsyncThunk} from "@reduxjs/toolkit";
import BasketService from "../../../api/BasketService";

export const asyncGetAllBasketsAction = createAsyncThunk(
    'baskets/getAllBaskets',
    async (query:any, thunkAPI)=>{
        try {
            const {page,limit,sort } = query;
            let response = await BasketService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncGetBasketAction = createAsyncThunk(
    'baskets/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await BasketService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);