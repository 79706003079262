import {FC,ReactNode} from 'react';
import './Tag.scss';

interface ITag {
    type?: string;
    color?: string;
    children?: ReactNode
}

const Tag:FC<ITag> = ({type='',color,children}) => {
    return (
        <div className={`tag tag--${type}`} style={{background:color,borderColor:color}}>
            {children}
        </div>
    );
};

export default Tag;