import React, {FC, useEffect} from 'react';
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import { useTranslation } from "react-i18next";
import SupportLayout from "../../components/templates/SupportLayout/SupportLayout";

import {Route, Routes} from "react-router-dom";
import Placeholder from "../../components/atoms/Placeholder/Placeholder";
import SupportMessages from "../../components/molecules/SupportMessages/SupportMessages";
import {io} from "socket.io-client";

import {useDispatchEx} from "../../hooks/redux";
import {addSupportMessage} from "../../redux/slices/supports/supportsSlice";
import './Supports.scss'

const Supports: FC = () => {
    const { t } = useTranslation();

    const dispatch = useDispatchEx();

    useEffect(() => {
        const token = localStorage.getItem('token');

        const socket = io('wss://api.vdoma.rfuler.site/supports', {
            extraHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        socket.on('receiveSupportMessage', (data) => {
            console.log('receiveSupportMessage',data);

            dispatch(addSupportMessage(data))
          /*  dispatch(increaseCountNotification(null));
            dispatch(setCurrentNotification(data));
            dispatch(addNotification(data))*/
        });

        socket.on('connect_error', (err) => {
            //  setError('Connection error: ' + err.message);
        });

        return () => {
            socket.disconnect();
        };
    }, []);
    return (
        <div className="container">
            <HeaderPanel title={t('supports_pages.title')} />

            <SupportLayout>
                <Routes>
                    <Route path=":userID" element={<SupportMessages />} />
                    <Route path="/" element={<Placeholder header={'Інформація'} message={'Оберіть чат для розмови'} />} />
                </Routes>
            </SupportLayout>
        </div>
    );
};

export default Supports;
