import {combineReducers,configureStore} from "@reduxjs/toolkit";
import modalsSlice from "./slices/modalsSlice";
import authSlice from "./slices/auth/authSlice";
import usersSlice from "./slices/users/usersSlice";
import articlesSlice from "./slices/articles/articlesSlice";
import attributesSlice from "./slices/attributes/attributesSlice";
import categoriesSlice from "./slices/categories/categoriesSlice";
import productsSlice from "./slices/products/productsSlice";
import ordersSlice from "./slices/orders/ordersSlice";
import settingsSlice from "./slices/settings/settingsSlice";
import infoAttributesSlice from "./slices/infoAttributes/infoAttributesSlice";
import transactionsSlice from "./slices/transactions/transactionsSlice";
import basketsSlice from "./slices/baskets/basketsSlice";
import statisticsSlice from "./slices/statistics/statisticsSlice";
import notificationsSlice from "./slices/notifications/notificationsSlice";
import supportsSlice from "./slices/supports/supportsSlice";

const rootReducer = combineReducers({
    auth: authSlice,
    users: usersSlice,
    orders: ordersSlice,
    baskets: basketsSlice,
    transactions: transactionsSlice,
    articles: articlesSlice,
    attributes: attributesSlice,
    infoAttributes: infoAttributesSlice,
    categories: categoriesSlice,
    products: productsSlice,
    modals: modalsSlice,
    settings: settingsSlice,
    statistics: statisticsSlice,
    notifications: notificationsSlice,
    supports: supportsSlice
});

export const store = configureStore({
    reducer: rootReducer
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch