import React, {useEffect, useState} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import {EditorState} from "draft-js";
import { asyncGetCategoryAction, asyncUpdateCategoryAction } from '../../redux/slices/categories/categoriesAction';
import {htmlToDraftBlocks} from "../../helpers/scripts";
import {useParams} from "react-router";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormCategory from "../../components/molecules/FormCategory/FormCategory";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";

const Category = () => {
    let {categoryID} = useParams();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const dispatch = useDispatchEx();
    const {category, isLoadingCategory} = useSelectorEx(state => state.categories);
    const config = useFormik({
        initialValues: {
            title: '',
            description: '',
            image: '',
            section_title: "",
            order:0,
            sub_category:-1,
            isShow: null
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });

    const formHandle = (value: any)=>{
        dispatch(asyncUpdateCategoryAction({...value,id:Number(categoryID)}));
    };
    useEffect(()=>{
        dispatch(asyncGetCategoryAction(Number(categoryID)));
    },[]);


    useEffect(()=>{
        if(category){
            config.setFieldValue('title',category?.title);
            config.setFieldValue('order',category?.order);
            config.setFieldValue('section_title',category?.section_title);
            config.setFieldValue('description',category?.description);
            config.setFieldValue('image',category?.image ? process.env.REACT_APP_URL_CATEGORY_IMG as string + category?.image : '');
            config.setFieldValue('isShow',category?.isShow);
            config.setFieldValue('sub_category',category?.sub_category);
            setEditorState(htmlToDraftBlocks(category?.description as string));
        }
    },[category]);
  //  if(isLoading) return <Preloader/>;
    // onClick={()=>config.handleSubmit()}
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('categories_pages.page.title')} onSave={()=>config.handleSubmit()}  />
                {!isLoadingCategory ?
                    <>
                        <FormCategory
                            editorState={editorState}
                            setEditorState={setEditorState}
                            title={''}
                            config={config}
                            image={process.env.REACT_APP_URL_CATEGORY_IMG as string + category?.image}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }


            </div>
         </>
    );
};

export default Category;