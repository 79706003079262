import React, {FC, ReactNode} from 'react';
import Sidebar from "../../components/molecules/Sidebar/Sidebar";
import {Outlet} from "react-router-dom";

interface ILayout {
    children?: ReactNode;
    className?:string;
}

const Layout:FC<ILayout> = ({children,className}) => {
    return (
        <>
            <div className="wrap">
                <Sidebar/>
                <section className={`page ${className}`}>
                    <div className="content">
                        <Outlet/>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Layout;