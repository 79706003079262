import React, {useState} from 'react';
import {useDispatchEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {EditorState} from "draft-js";
import { asyncCreateCategoryAction } from '../../redux/slices/categories/categoriesAction';
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";

import FormCategory from "../../components/molecules/FormCategory/FormCategory";
import {useTranslation} from "react-i18next";

const CreateCategory = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const dispatch = useDispatchEx();

    const config = useFormik({
        initialValues: {
            title: '',
            description: '',
            image: '',
            order: 0,
            isShow: false,
            sub_category: -1
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });

    const formHandle = (value: any)=>{
        dispatch(asyncCreateCategoryAction(value));
        config.resetForm();
        setEditorState(EditorState.createEmpty());
    };
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('categories_pages.create.title')} onSave={()=>config.handleSubmit()}  />
                <FormCategory
                    editorState={editorState}
                    setEditorState={setEditorState}
                    title={''}
                    config={config}
                />

            </div>

        </>
    );
};

export default CreateCategory;