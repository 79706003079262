import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    asyncCreateSupportMessageAction,
    asyncGetSupportAction,
    asyncGetSupportsAction
} from "./supportsAction";
import {ISupportMessage} from "../../../helpers/types";



interface supportsState {
    dialogs: any[];
    messages: ISupportMessage[]
    support: ISupportMessage | null;
  //  totalSupports: number;
    isLoading: boolean;
    error: string;
}

const initialState: supportsState = {
    dialogs: [],
    messages: [],
    support: null,
  //  totalSupports: 0,
    isLoading: false,
    error: ''
};


const supportsSlice = createSlice({
    name: 'supportsSlice',
    initialState,
    reducers: {
        addSupportMessage(state,action) {
            state.messages = [...state.messages,action.payload];
        },
        updateDialogsCount(state,action) {
            state.dialogs = state.dialogs.map(dialog => {
                if(dialog.userId === action.payload.userId) {
                    return {
                        ...dialog,
                        count: action.payload.count
                    }
                }
                else {
                    return {
                        ...dialog
                    }
                }
            });
        },
        resetSupports(state) {
            state = initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(asyncGetSupportsAction.fulfilled, (state: supportsState, action: PayloadAction<any>) => {
                //const { rows, count } = action.payload;
                state.isLoading = false;
                state.error = '';
                state.dialogs = action.payload;
              //  state.totalSupports = count;
                // toast.success('Supports received!', tostSetting);
            })
            .addCase(asyncGetSupportsAction.pending, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = true;
            })
            .addCase(asyncGetSupportsAction.rejected, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(asyncGetSupportAction.fulfilled, (state: supportsState, action: PayloadAction<any>) => {
                const { rows, count } = action.payload;
                state.isLoading = false;
                state.error = '';
                state.messages = rows;
            })
            .addCase(asyncGetSupportAction.pending, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = true;
            })
            .addCase(asyncGetSupportAction.rejected, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(asyncCreateSupportMessageAction.fulfilled, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = '';
                state.messages = [...state.messages,action.payload];
            })
            .addCase(asyncCreateSupportMessageAction.pending, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = true;
            })
            .addCase(asyncCreateSupportMessageAction.rejected, (state: supportsState, action: PayloadAction<any>) => {
                state.isLoading = false;
                state.error = action.payload;
            })


    }
});

export const {addSupportMessage, resetSupports } = supportsSlice.actions;
export default supportsSlice.reducer;
