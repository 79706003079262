import instance from "./api";
import {AxiosResponse} from 'axios';
import {ICategory} from "../helpers/types";

export default class CategoriesService {
    static async findAll(): Promise<AxiosResponse<any>> {
        return instance.get<ICategory[]>('categories' )
    }

    static async findOne(categoryID:number): Promise<AxiosResponse<ICategory>> {
        return instance.get<ICategory>(`categories/${categoryID}` )
    }

    static async create(category:FormData): Promise<AxiosResponse<ICategory>> {
        return instance.post<ICategory>(`categories`,category)
    }

    static async update(category:FormData,categoryID:number): Promise<AxiosResponse<ICategory>> {
        return instance.put<ICategory>(`categories/${categoryID}`,category)
    }

    static async delete(categoryID:number): Promise<AxiosResponse<ICategory>> {
        return instance.delete<ICategory>(`categories/${categoryID}`)
    }
}