import React, {FC, useEffect, useState} from 'react';

import { FormikValues} from "formik";
import { IProduct} from "../../../helpers/types";
import './FormAttribute.scss'
import Input from "../../atoms/Input/Input";
import ErrorMessage from "../../old/atoms/ErrorMessage/ErrorMessage";

import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";
import {asyncGetCategoriesAction} from "../../../redux/slices/categories/categoriesAction";
import Preloader from "../../atoms/Preloader/Preloader";
import Repeater from "../Repeater/Repeater";
import Select from "../../atoms/Select/Select";
import {useTranslation} from "react-i18next";


interface IProductForm {
    title: string;
    config: FormikValues;


    product?: IProduct | null
}

const FormAttribute:FC<IProductForm> = ({title, config, product}) => {
    const {values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm} = config;
    const {categories,isLoading:isLoadingCategory} = useSelectorEx(state => state.categories);

    const dispatch = useDispatchEx();
    useEffect(()=>{
        dispatch(asyncGetCategoriesAction());
    },[]);
    const addTag = ()=>{
        setFieldValue('values', [...values.values, ''])
    }

    const onChangeCategory = (value:any)=> {
        //console.log("onChangeCategory",value,initDataProduct.category)
        setFieldValue('categoryId', Number(value));
    }
    const { t } = useTranslation();
    if(isLoadingCategory) return <Preloader/>;

    return (
        <div className="form-attribute">
            <div className="form-attribute__wrapper">
                <div className="form-attribute__block">
                    <div className="form-attribute__inner">

                        <div className="form-attribute__input">
                            <Input className={errors.name && touched.name ? "error": ""} value={values.name} onBlur={handleBlur} onChange={handleChange} name={'name'} placeholder={t('attributes_pages.fields.name')} />
                            <ErrorMessage message={errors.name && touched.name && errors.name}/>
                        </div>
                        <div className="form-attribute__input">
                            {categories?.length ?

                                <Select
                                    type={'search'}
                                    defaultValue={values.categoryId?.toString()}
                                    className={errors.categoryId && touched.categoryId ? "error": ""}
                                    onChange={onChangeCategory}
                                    placeholder={t('attributes_pages.fields.category')}
                                    items={categories.map((item:any)=>{
                                        return {
                                            value: item.id.toString(),
                                            name: item.title as string
                                        }
                                    })}
                                />
                                : ""}
                        </div>
                    </div>

                </div>
                <div className="form-attribute__block">
                    <div className="form-attribute__input">
                        <Repeater
                            subtitle={t('attributes_pages.fields.values.value')}
                            title={t('attributes_pages.fields.values.title')}
                            name={'values'}
                            values={values.values}
                            items={values.values}
                            errors={errors.values}
                            touched={errors.values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            onAddItem={addTag}
                            onDeleteItem={(id)=>{
                                setFieldValue('values', values.values.filter((item:any,index:any) => id !== index))
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormAttribute;