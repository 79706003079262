import instance from "./api";
import {AxiosResponse} from 'axios';
import { IProduct } from "../helpers/types";

export default class ProductsService {
  static async findAll({
                         search, sort, limit, page, category
}:any): Promise<AxiosResponse<any>> {
    return instance.get('products' ,
        {
          params: {
            search,
            category:category,
            sort,
            limit,
            page
          }
        })
  }

  static async findOne(productID:number): Promise<AxiosResponse<IProduct>> {
    return instance.get<IProduct>(`products/id/${productID}` )
  }

static async import(product:FormData): Promise<AxiosResponse<IProduct>> {
    return instance.post<IProduct>(`products/import`,product)
}

  static async create(product:FormData): Promise<AxiosResponse<IProduct>> {
    return instance.post<IProduct>(`products`,product)
  }

  static async update(product:FormData,productID:number): Promise<AxiosResponse<IProduct>> {
    return instance.put<IProduct>(`products/${productID}`,product)
  }

  static async delete(productID:number): Promise<AxiosResponse<IProduct>> {
    return instance.delete<IProduct>(`products/${productID}`)
  }
}