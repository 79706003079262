import {createAsyncThunk} from "@reduxjs/toolkit";
import {ISetting} from "../../../helpers/types";
import SettingsService from "../../../api/SettingsService";

export const asyncGetSettingsAction = createAsyncThunk(
    'settings/all',
    async (query:any, thunkAPI)=>{
        try {
            const {sort,page,limit} = query;
            let response = await SettingsService.findAll(sort,limit,page);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetSettingAction = createAsyncThunk(
    'settings/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await SettingsService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateSettingAction = createAsyncThunk(
    'settings/create',
    async (setting:ISetting, thunkAPI)=>{
        try {
            const {title,value} = setting;

            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('value', value as string);

            let response = await SettingsService.create(formData);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateSettingAction = createAsyncThunk(
    'settings/update',
    async (setting:ISetting, thunkAPI)=>{
        try {
            const {id,title,value} = setting;
            const formData = new FormData();
            formData.append('title', title as string);
            formData.append('value', value as string);

            let response = await SettingsService.update(formData, id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteSettingAction = createAsyncThunk(
    'settings/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await SettingsService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);