import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {ITransaction} from "../../../helpers/types";
import {asyncGetAllTransactionsAction} from "./transactionsAction";

interface transactionsState {
    transactions: ITransaction[];
    totalTransactions:number;
    currentPage:number;
    article: ITransaction | null
    isLoading: boolean;
    error: string
}

const initialState: transactionsState = {
    transactions: [],
    totalTransactions:0,
    currentPage: 1,
    article: null,
    isLoading: false,
    error: ''
};


const transactionsSlice = createSlice({
    name:'transactionsSlice',
    initialState,
    reducers:{
        setCurrentPageTransactions(state,action){
            state.currentPage = action.payload;
        },
        resetArticles(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetAllTransactionsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalTransactions = count;
            state.transactions =rows;
        },
        [asyncGetAllTransactionsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetAllTransactionsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

    }
});

export const {resetArticles,setCurrentPageTransactions} = transactionsSlice.actions;
export default transactionsSlice.reducer;