import {createSelector} from "reselect";
import {RootState} from "../redux/store";
import {getRandomColor, getTimeDate} from "../helpers/scripts";
export const selectProducts = (state:RootState) => state.products.products;
export const selectCategories = (state:RootState) => state.categories.categories;
export const selectCategoriesToListList = createSelector(selectCategories, (products)=>{
    return [
/*        {
            name: "Всі товари",
            value: null
        },*/
        ...products.map((item:any)=>{
        return {
            name: item?.title,
            value: item?.id
        }
    })]
});
export const selectProductsList = createSelector(selectProducts, (products)=>{
    return products.map((item:any)=>{
        return {
            key: item.id.toString(),
            id: item.id as number,
            image: item.image && item.image !== 'null' ? process.env.REACT_APP_URL_PRODUCT_IMG as string+item.image as string : null,
            title: item.title as string,
            price: item.price as number,
            category: item.category?.title as string
        }
    })
});

export const selectTransactions = (state:RootState) => state.transactions.transactions;

export const selectTransactionsList = createSelector(selectTransactions, (trans)=>{
    return trans.map((item:any)=>{
        return {
            keyID: item.id,
            id: item?.transactionOrder?.orderId,
            title: item?.author?.email,
            date: getTimeDate(item.createdAt as string),
            status: {
                name:item.status,
                color: getRandomColor(item.status)
            },
            total: item.amount,
        }
    })
});

export const selectBaskets = (state:RootState) => state.baskets.baskets;

export const selectBasketsList = createSelector(selectBaskets, (baskets)=>{
    return baskets.map((item:any)=>{
        return {
            keyID: item.id,
            id: item.userID,
            title: item?.author?.email,
            date: getTimeDate(item.createdAt as string),
            status: {
                name:`${item.totalCount} шт.`,
                color: getRandomColor(item.totalCount)
            },
           total: item.totalPrice,
        }
    })
});