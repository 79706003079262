import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncCreateInfoAttributeAction, asyncDeleteInfoAttributeAction,
    asyncGetInfoAttributeAction,
    asyncGetInfoAttributesAction,
    asyncUpdateInfoAttributeAction
} from "./infoAttributesAction";
import {IInfoAttribute} from "../../../helpers/types";

interface infoAttributesState {
    infoAttributes: IInfoAttribute[];
    infoAttribute: IInfoAttribute | null
    isLoading: boolean;
    error: string
}

const initialState: infoAttributesState = {
    infoAttributes: [],
    infoAttribute: null,
    isLoading: false,
    error: ''
};


const infoAttributesSlice = createSlice({
    name:'infoAttributesSlice',
    initialState,
    reducers:{
        resetInfoAttributes(state){
            state = initialState;
        },
    },
    extraReducers: {
        [asyncGetInfoAttributesAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
           // const [attributes] = action.payload;
            state.isLoading = false;
            state.error = '';
            state.infoAttributes = action.payload;
        },
        [asyncGetInfoAttributesAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetInfoAttributesAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetInfoAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.infoAttribute = action.payload;
        },
        [asyncGetInfoAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetInfoAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncUpdateInfoAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.infoAttribute = action.payload;
            message.success('Info Attribute created');
        },
        [asyncUpdateInfoAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateInfoAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncCreateInfoAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.infoAttributes.push(action.payload);
            message.success('Info Attribute created');
        },
        [asyncCreateInfoAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncCreateInfoAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },

        [asyncDeleteInfoAttributeAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.infoAttributes = state.infoAttributes.filter(item=>item.id !== action.payload.id)
            message.success('Info Attribute deleted');
        },
        [asyncDeleteInfoAttributeAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteInfoAttributeAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
    }
});

export const {resetInfoAttributes} = infoAttributesSlice.actions;
export default infoAttributesSlice.reducer;