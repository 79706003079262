import instance from "./api";
import {AxiosResponse} from 'axios';
import { IAttribute} from "../helpers/types";

export default class InfoAttributesService {
    static async findAll(category:number): Promise<AxiosResponse<any>> {
        return instance.get<IAttribute[]>('infoAttributes' ,{
            params: {
                category,
            }
        })
    }

    static async findOne(attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.get<IAttribute>(`infoAttributes/${attributeID}` )
    }

    static async create(attribute:IAttribute): Promise<AxiosResponse<IAttribute>> {
        return instance.post<IAttribute>(`infoAttributes`,attribute)
    }

    static async update(attribute:IAttribute,attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.put<IAttribute>(`infoAttributes/${attributeID}`,attribute)
    }
    static async delete(attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.delete<IAttribute>(`infoAttributes/${attributeID}`)
    }
}