import React, {FC, useEffect} from 'react';
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {asyncDeleteCategoryAction, asyncGetCategoriesAction} from "../../redux/slices/categories/categoriesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import CategoryImageList from "../../components/molecules/CategoryImageList/CategoryImageList";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useTranslation} from "react-i18next";

interface DataType {
    key: string;
    id: number;
    image:string;
    title: string;
}

const Categories:FC = () => {

    const dispatch = useDispatchEx();
    useEffect(()=>{
        dispatch(asyncGetCategoriesAction());
    },[]);

    const {isLoading,categories} = useSelectorEx(state => state.categories);
    const data: DataType[] = categories.map((item:any)=>{
        return {
            key: item.id.toString(),
            id: item.id,
            title: item.title as string,
            image: item?.image ? process.env.REACT_APP_URL_CATEGORY_IMG as string +item?.image as string : ''
        }
    });

    const onDelete = (id:number)=>{
        dispatch(asyncDeleteCategoryAction(id));
    }
    const { t } = useTranslation();
    //if(isLoading) return <Preloader/>;
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('categories_pages.title')} addUrl={'/categories/create'} />
                {!isLoading ?
                    <>
                        <CategoryImageList onDelete={onDelete} link={'/categories'} items={data}/>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>

        </>
    );
};

export default Categories;