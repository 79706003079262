import React from 'react';

import {useDispatchEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import {asyncCreateAttributeAction} from "../../redux/slices/attributes/attributesAction";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormAttribute from "../../components/molecules/FormAttribute/FormAttribute";
import {useTranslation} from "react-i18next";


const CreateAttribute = () => {
    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            name: '',
            values: [],
            categoryId: ''
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
        const changedValues = {
            ...values,
            values: values.values.join(',')
        }
        dispatch(asyncCreateAttributeAction(changedValues));
        config.resetForm();
    };
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={t('attributes_pages.create.title')} onSave={()=>config.handleSubmit()}  />
                <FormAttribute
                    title={''}
                    config={config}
                />
            </div>

        </>
    );
};

export default CreateAttribute;