import React, {FC, useEffect} from 'react';
import {useParams} from "react-router";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";

import {
    asyncUpdateOrderAction
} from "../../redux/slices/orders/ordersAction";
import { getTimeDate} from "../../helpers/scripts";
import OrderProductItem from "../../components/atoms/OrderProductItem/OrderProductItem";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import ErrorMessage from "../../components/atoms/ErrorMessage/ErrorMessage";
import Input from '../../components/atoms/Input/Input';


import {asyncGetBasketAction} from "../../redux/slices/baskets/basketsAction";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import Placeholder from "../../components/atoms/Placeholder/Placeholder";
import {useTranslation} from "react-i18next";

const Basket:FC = () => {
    let {basketID} = useParams();

    const dispatch = useDispatchEx();

    const {basket, isLoading} = useSelectorEx(state => state.baskets);
    const {values,handleChange,errors,touched,handleSubmit,handleBlur,setFieldValue} = useFormik({
        initialValues: {

           /* total: order?.items?.reduce((a:any,b:any)=>{
                return a+(b?.OrderItems?.price*b?.OrderItems?.count)
            },0),*/
            items: basket?.length ? basket.map(((item:any)=>{
                const priceProductTypes = item.product.types ? JSON.parse(item.product.types) : null;
                const priceProduct = priceProductTypes.filter((type:any)=>type.name === item.type)[0]?.price
                return {
                    image: item.product.image,
                    title: item?.product?.title,
                    count: item.count,
                    price: priceProduct ? priceProduct:  item?.product?.price
                }
            })) : [],
            email:basket ? basket![0]?.author?.email : "",
            createdAt:basket ? basket![0]?.createdAt : "",
            phone:basket ? basket![0]?.author?.phone : "",

        },
        enableReinitialize: true,
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (value: any)=>{
        dispatch(asyncUpdateOrderAction({
            id:Number(basketID),
            ...value,
        }));
    };

    useEffect(()=>{
        dispatch(asyncGetBasketAction(Number(basketID)));
    },[]);
    //if(!basket) return <Preloader/>;
/*    const totalPrice = order?.items?.reduce((a:any,b:any)=>{
        return a+(b?.OrderItems?.price*b?.OrderItems?.count)
    },0)*/
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                {basket ?
                    <>
                        <HeaderPanel title={`${t('baskets_pages.page.title')} № ${Number(basketID)} ${getTimeDate(basket![0]?.createdAt as string)}`}  />
                        <form onSubmit={handleSubmit}> <div className="order-form">
                            <div className="order-form__wrapper">

                                <div className="order-form__input">
                                    <Input className={errors.phone && touched.phone ? "error": ""} value={values.phone} onBlur={handleBlur} onChange={handleChange} name={'phone'} placeholder={t('baskets_pages.fields.phone')} />
                                    <ErrorMessage message={errors.phone && touched.phone && errors.phone}/>
                                </div>

                                <div className="order-form__input">
                                    <Input className={errors.email && touched.email ? "error": ""} value={values.email} onBlur={handleBlur} onChange={handleChange} name={'email'} placeholder={'Email'} />
                                    <ErrorMessage message={errors.email && touched.email && errors.email}/>
                                </div>

                            </div>
                            <div className="order-form__inner">
                                <div className="order-form__items">
                                    {values?.items ? values?.items?.map((item:any)=>{
                                        return <OrderProductItem key={item.id} image={item.image as string} title={item.title as string} count={item?.count} price={item?.price}/>
                                    }): <Placeholder header={'Ups...'} message={"Products not found"}/>}
                                </div>
                            </div>
                        </div></form>
                    </>
                    :
                    <PreloaderContent/>
                }

            </div>
        </>
    );
};

export default Basket;