import React, {FC, useEffect, useRef, useState} from 'react';
import SupportDialogsItem from "../SupportDialogsItem/SupportDialogsItem";
import {getTimeDate} from "../../../helpers/scripts";
import {useDispatchEx, useSelectorEx} from "../../../hooks/redux";

import './SupportMessages.scss'
import {asyncCreateSupportMessageAction, asyncGetSupportAction} from "../../../redux/slices/supports/supportsAction";
import {useParams} from "react-router";
import TextArea from "../../atoms/TextArea/TextArea";
import {useFormik} from "formik";
import AttachIcon from "../../atoms/icons/AttachIcon";
import AddImageIcon from "../../atoms/icons/AddImageIcon";
import ButtonRect from "../../atoms/ButtonRect/ButtonRect";
import CloseIcon from "../../atoms/icons/CloseIcon";

const SupportMessages:FC = () => {
    const {messages} = useSelectorEx(state => state.supports);
    const inputRef = useRef<any>();
    const [url, setUrl] = useState<string[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const dispatch = useDispatchEx();
    let {userID} = useParams();

    useEffect(() => {
        dispatch(asyncGetSupportAction(Number(userID)));
    }, [userID]);


    const {errors,touched,values, resetForm,handleChange,handleBlur,handleSubmit,setFieldValue} = useFormik({
        initialValues: {
            message: '',
        },
        enableReinitialize: true,
        validationSchema:null,
        onSubmit: (value) => formHandle(value),
    });

    const formHandle = (values: any)=>{
        console.log(`files`,files)
        dispatch(asyncCreateSupportMessageAction({
            userId: Number(userID),
            ...values,
            files,
        }));
        setUrl([]);
        setFiles([]);
        inputRef.current.value = "";
        resetForm();
    };
    const handleKeyDown = (event:any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent adding a newline in the TextArea
            handleSubmit();  // Submit the form
        }
    };

    const {user} = useSelectorEx(state => state.auth);
    const messagesContainerRef = useRef<any>(null);

    useEffect(() => {
        if (messagesContainerRef.current) {
            const { scrollHeight, clientHeight } = messagesContainerRef.current;
            messagesContainerRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, [messages]);


    const onChangeFile = (e:React.ChangeEvent<HTMLInputElement>)=>{
        const imageList:string[] = [];
        const arrayList = Array.from(e.target.files as FileList);
        for(let value of arrayList){
            imageList.push(URL.createObjectURL(value))
        }
        inputRef.current.value = "";
      // console.log('inputRef',e.target.files)
        setUrl(prevState => [...prevState,...imageList]);
        setFiles(prevState => [...prevState,...arrayList]);
    };

    const onRemoveFile = (id:number)=>{
        setFiles(prevState => prevState.filter(item=>item !== prevState[id]));
        setUrl(prevState => prevState.filter(item=>item !== prevState[id]));
       // onChange();files.filter(item=>item !== files[id])
      //  onDelete(id)
    };
    return (
        <>
            <div className={'supports__messages'} ref={messagesContainerRef}>
                {messages.map((dialog, index) => (
                    <SupportDialogsItem
                        key={index}
                        className={user.id === dialog.fromId ? "supports-dialogs__item--reverse" : ""}
                        name={user.id === dialog.fromId  ? "Ви" : (dialog?.authorFrom?.email ? dialog?.authorFrom?.email : "")  }
                        message={dialog.message}
                        attachedFiles={
                            dialog.attachedFiles
                                ? dialog.attachedFiles
                                    .split(',')
                                    .filter(item => item) // Убираем пустые строки
                                    .map(item => `${process.env.REACT_APP_URL_SERVER}/chats/${item}`)
                                : []
                        }
                        isRead={dialog.isRead}
                        date={getTimeDate(dialog.createdAt)}
                    />
                ))}
            </div>
            <div className="supports__form">
                <div className="supports__form-images">
                    {url.length ?
                            url.map((item,index)=>{
                                return <div key={index} className="supports__form-img">
                                    <img src={item} alt=""/>
                                    <div className="supports__form-controls">
                                        <ButtonRect className={'btn-rect--small'} onClick={()=>onRemoveFile(index)} colorType={'red'} name={<CloseIcon/>}/>
                                    </div>
                                </div>
                            })
                        :
                        ""
                    }
                </div>
                <div className="supports__form-wrapper">
                    <div className="supports__form-attach">
                        <AttachIcon/>
                        <input ref={inputRef} name={'files'} type="file" multiple={true}   accept=".jpg, .jpeg, .png, .webp, image/jpeg, image/png, image/webp" onChange={onChangeFile} />
                    </div>
                    <TextArea onKeyDown={handleKeyDown} value={values.message}  onBlur={handleBlur} onChange={handleChange} name={'message'} placeholder={''} />
                    <div className="supports__button">

                    </div>
                </div>


            </div>
        </>

    );
};

export default SupportMessages;