export function replaceEnterWithBr(text:string) {
    return text.replace(/\n/g, '<br/>');
}

export function replaceBrWithEnter(text:string) {
    return text.replace(/<br\s*\/?>/g, '\n');
}

export function generateSlug(text:string) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')           // Заменяет пробелы на дефисы
        .replace(/[^\w\-]+/g, '')       // Удаляет все не буквенно-цифровые символы кроме дефисов
        .replace(/\-\-+/g, '-')         // Удаляет лишние дефисы
        .replace(/^-+/, '')             // Удаляет дефисы в начале строки
        .replace(/-+$/, '');            // Удаляет дефисы в конце строки
}