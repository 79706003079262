import {createAsyncThunk} from "@reduxjs/toolkit";
import { IInfoAttribute} from "../../../helpers/types";
import InfoAttributesService from "../../../api/InfoAttributesService";


export const asyncGetInfoAttributesAction = createAsyncThunk(
    'infoAttributes/all',
    async (category:any, thunkAPI)=>{
        try {
            let response = await InfoAttributesService.findAll(category);
            return response.data.reverse();
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
export const asyncGetInfoAttributeAction = createAsyncThunk(
    'infoAttributes/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await InfoAttributesService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncCreateInfoAttributeAction = createAsyncThunk(
    'infoAttributes/create',
    async (attribute:IInfoAttribute, thunkAPI)=>{
        try {
            let response = await InfoAttributesService.create(attribute);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncUpdateInfoAttributeAction = createAsyncThunk(
    'infoAttributes/update',
    async (attribute:IInfoAttribute, thunkAPI)=>{
        try {
            let response = await InfoAttributesService.update(attribute, attribute.id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);

export const asyncDeleteInfoAttributeAction = createAsyncThunk(
    'infoAttributes/delete',
    async (id:number, thunkAPI)=>{
        try {
            let response = await InfoAttributesService.delete(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);