import React, {FC, useEffect, useState} from 'react';

import { EditorState} from "draft-js";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import {
    asyncDeleteProductAction,
    asyncGetProductAction,
    asyncUpdateProductAction
} from '../../redux/slices/products/productsAction';
import {useParams} from "react-router";
import {htmlToDraftBlocks} from "../../helpers/scripts";
import {replaceBrWithEnter} from "../../helpers/functions";
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormProduct from "../../components/molecules/FormProduct/FormProduct";
import PreloaderContent from "../../components/atoms/PreloaderContent/PreloaderContent";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Product:FC = () => {
    let {productID} = useParams();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorsState, setEditorsState] = useState({});

    const dispatch = useDispatchEx();

    const {product, isLoading} = useSelectorEx(state => state.products);
    const infoAttributesProduct = product?.infoAttributes![0]?.values ? JSON.parse(product?.infoAttributes![0]?.values) : [];
    const oldTypes =  product?.types ? JSON.parse(product?.types): [];
    const newTypes = oldTypes.map((item:any)=>{
        return {
            ...item,
            desc: replaceBrWithEnter(item.desc)
        }
    })
    const config = useFormik({
        initialValues: {
            title: product?.title as string,
            content: product?.content as string,
            image: product?.image as string,
            price: product?.price as number,
            sale: product?.sale as number,
            gallery: [],
            brand: product?.brand as string,
            slug: product?.slug as string,
            seo_title: product?.seo_title as string,
            seo_description: product?.seo_description as string,
            sub_title: product?.sub_title as string,
            description: product?.description as string,
            colors: product?.colorList ? product?.colorList?.map((item:any)=>{
                return {
                    name: item.name,
                    color: item.color,
                    link: item?.linkID,
                }
            }): [],
            typesName:product?.typesName as string,
            types: newTypes,
            infoAttributes:infoAttributesProduct,
            oldGallery: product?.gallery as string,
            category: product?.categoryId,
            isAvailable: product?.isAvailable,
            isNewest: product?.isNewest,
            isBest: product?.isBest,
            attributes: product?.attributes?.map((item:any)=>{
                return {
                    id: item.id,
                    name: item.name,
                    values: item.ProductAttribute?.values
                }
            })
        },
        enableReinitialize: true,
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });
    const formHandle = (values: any)=>{
        dispatch(asyncUpdateProductAction({
            id:Number(productID),
            ...values,
        },));
    };

    useEffect(()=>{
        if(productID){
            dispatch(asyncGetProductAction(Number(productID)));
        }
    },[productID]);

    const {infoAttributes} = useSelectorEx(state => state.infoAttributes);
    const infoAttributesNamesList:any = infoAttributes![0]?.values ? infoAttributes![0]?.values.split(',') : [];

    useEffect(()=>{
        if(product){
/*            config.setValues({
                "title":product?.title,
                "post":"hoii"
            });*/

            config.setFieldValue('image',product?.image ? process.env.REACT_APP_URL_PRODUCT_IMG as string + product?.image : null)


            const infoAttributesList:any = product?.infoAttributes![0]?.values ? JSON.parse(product?.infoAttributes![0]?.values) : [];

            const initialEditorsState:any = {};
            infoAttributesList.forEach((item:any, index:number) => {
                const indexInfo = infoAttributesNamesList?.findIndex((attr:string)=>attr === item?.name)
                if(infoAttributesNamesList.includes(item.name)){
                    initialEditorsState[indexInfo] = htmlToDraftBlocks(item?.values ?  item?.values : "");
                }

            });
            setEditorsState(initialEditorsState);

            setEditorState(htmlToDraftBlocks(product?.content as string));
        }
    },[product]);
  //  if(!product) return <Preloader/>;

    const gallery = product?.gallery as string;
    const galleryArray = gallery ? gallery?.split(',') : [];
    const galleryMap = galleryArray?.length > 0 ? galleryArray?.map((item:any)=>process.env.REACT_APP_URL_PRODUCT_IMG+item) : [];
    //onClick={()=>config.handleSubmit()}

    const navigate = useNavigate();
    const onDeleteProduct = ()=>{
        dispatch(asyncDeleteProductAction(Number(productID)));
        navigate('/products');
    }
    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={`${t('products_pages.page.title')}`} onDelete={onDeleteProduct} onSave={()=>config.handleSubmit()}  />
                {!isLoading ?
                    <>
                        <FormProduct
                            setEditorsState={setEditorsState}
                            editorsState={editorsState}
                            editorState={editorState}
                            setEditorState={setEditorState}

                            title={''}
                            config={config}
                            product={product}
                        //    image={process.env.REACT_APP_URL_PRODUCT_IMG as string + product?.image}
                            gallery={galleryMap}
                        />
                    </>
                    :
                    <PreloaderContent/>
                }
            </div>
        </>
    );
};

export default Product;