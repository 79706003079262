import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import './SupportDialogsItem.scss';
import {FC, useEffect} from "react";
import TextAvatar from "../../atoms/TextAvatar/TextAvatar";

interface ISupportDialogsItem {
    className?: string;
    name: string;
    message: string;
    attachedFiles?: string[];
    date: string;
    count?: number;
    isRead:boolean
}

const SupportDialogsItem: FC<ISupportDialogsItem> = ({className='', name, message,date,isRead=true,attachedFiles=[],count=0 }) => {
    useEffect(() => {
        Fancybox.bind("[data-fancybox]", {
            // Здесь можно добавить дополнительные настройки Fancybox
        });

        // Очистка при размонтировании компонента
        return () => {
            Fancybox.destroy();
        };
    }, []);
    return <div className={`supports-dialogs__item ${className} `}>
        <div className="supports-dialogs__item-img">
            <TextAvatar className={`text-avatar--big`} name={name} />
        </div>
        <div className="supports-dialogs__item-body">
            <div className="supports-dialogs__item-name">{name}</div>
            <div className="supports-dialogs__item-message">{message}</div>
            {count ? <div className="supports-dialogs__item-count">{count}</div> : ""}
            <div className="supports-dialogs__item-files">{
                attachedFiles.length > 0 ? attachedFiles.map((item,index)=> {
                        return <div key={index} className="supports-dialogs__item-file">
                            <a href={item} data-fancybox="gallery">
                                <img src={item} alt={`Image ${index+1}`} />
                            </a>
                        </div>
                    })
                    : ""}</div>

            <div className="supports-dialogs__item-date">
                <span>{date}</span>
                {!isRead ?
                    <div className="supports-dialogs__item-new"></div>
                    : ""}

            </div>
        </div>
    </div>
};

export default SupportDialogsItem;
