import React, {useEffect, useState} from 'react';

import { EditorState} from "draft-js";
import {useDispatchEx, useSelectorEx} from "../../hooks/redux";
import {useFormik} from "formik";
import {valSchemaSettings} from "../../helpers/schemas";
import { asyncCreateProductAction } from '../../redux/slices/products/productsAction';
import HeaderPanel from "../../components/molecules/HeaderPanel/HeaderPanel";
import FormProduct from "../../components/molecules/FormProduct/FormProduct";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {changeRedirect} from "../../redux/slices/products/productsSlice";


const CreateProduct = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorsState, setEditorsState] = useState({});

    const dispatch = useDispatchEx();
    const config = useFormik({
        initialValues: {
            title: '',
            content: '',
            image: '',
            price: '',
            slug: '',
            brand: "",
            seo_title: '',
            seo_description:'',
            sub_title: '',
            description: '',
            colors:[],
            typesName:'',
            types:[],
            sale: 0,
            gallery: [],
            infoAttributes: [],
            category: null,
            isAvailable: true,
            isNewest: true,
            isBest: false,
            attributes: []
        },
        validationSchema:valSchemaSettings,
        onSubmit: (value) => formHandle(value),
    });

    useEffect(()=>{

            const infoAttributesList:any = []//product?.infoAttributes ? JSON.parse(product?.infoAttributes?.values) : [];
            const initialEditorsState:any = {};
            infoAttributesList.forEach((item:any, index:number) => {
                initialEditorsState[index] = EditorState.createEmpty();
            });
            setEditorsState(initialEditorsState);

    },[]);
    const formHandle = (value: any)=>{
        dispatch(asyncCreateProductAction(value));
        //config.resetForm();
        setEditorState(EditorState.createEmpty());
    };

    const {shouldRedirectProduct} = useSelectorEx(state => state.products)
    const navigate = useNavigate();
    useEffect(() => {
        if(shouldRedirectProduct){
            navigate("/products");
            dispatch(changeRedirect(false))
        }
    }, [shouldRedirectProduct]);

    const { t } = useTranslation();
    return (
        <>
            <div className="container">
                <HeaderPanel title={`Cтворення товару`} onSave={()=>config.handleSubmit()}  />
                <FormProduct
                    setEditorsState={setEditorsState}
                    editorsState={editorsState}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    title={''}
                    config={config}
                />

            </div>
        </>
    );
};

export default CreateProduct;