import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {asyncGetAllBasketsAction, asyncGetBasketAction} from "./basketsAction";

interface transactionsState {
    baskets: any[];
    totalBaskets:number;
    currentPage:number;
    basket: any | null
    isLoading: boolean;
    error: string
}

const initialState: transactionsState = {
    baskets: [],
    totalBaskets:0,
    currentPage: 1,
    basket: null,
    isLoading: false,
    error: ''
};


const basketsSlice = createSlice({
    name:'basketsSlice',
    initialState,
    reducers:{
        resetBaskets(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetAllBasketsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalBaskets = count;
            state.baskets = rows;
        },
        [asyncGetAllBasketsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetAllBasketsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetBasketAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.basket = action.payload;
        },
        [asyncGetBasketAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetBasketAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

    }
});

export const {resetBaskets} = basketsSlice.actions;
export default basketsSlice.reducer;