import {createAsyncThunk} from "@reduxjs/toolkit";

import UsersService from "../../../api/UsersService";

export const asyncGetUsersAction = createAsyncThunk(
    'users/all',
    async (_, thunkAPI)=>{
        try {
            let response = await UsersService.findAll();
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);


export const asyncGetUserAction = createAsyncThunk(
    'users/one',
    async (id:number, thunkAPI)=>{
        try {
            let response = await UsersService.findOne(id);
            return response.data;
        } catch (e:any) {
            return thunkAPI.rejectWithValue(e.response?.data?.message)
        }
    }
);
