import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";

import {message} from "antd";
import {
    asyncDeleteOrderAction,
     asyncGetOrderAction,
    asyncGetOrdersAction,
    asyncUpdateOrderAction
} from "./ordersAction";
import {IOrder} from "../../../helpers/types";

interface ordersState {
    orders: IOrder[];
    totalOrders:number;
    order: IOrder | null
    isLoading: boolean;
    error: string
}

const initialState: ordersState = {
    orders: [],
    totalOrders:0,
    order: null,
    isLoading: false,
    error: ''
};


const ordersSlice = createSlice({
    name:'ordersSlice',
    initialState,
    reducers:{
        resetOrders(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetOrdersAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            const {rows,count} = action.payload;
            state.isLoading = false;
            state.error = '';
            state.totalOrders = count;
            state.orders =rows;
            //message.success('Orders received');
        },
        [asyncGetOrdersAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetOrdersAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncGetOrderAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.order = action.payload;
        },
        [asyncGetOrderAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetOrderAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

        [asyncUpdateOrderAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.order = action.payload;
            message.success('Order updated');
        },
        [asyncUpdateOrderAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncUpdateOrderAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
        [asyncDeleteOrderAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.orders = state.orders.filter(item=>item.id !== action.payload.id);
            message.success('Order deleted');
        },
        [asyncDeleteOrderAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncDeleteOrderAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload;
            message.error(action.payload);
        },
    }
});

export const {resetOrders} = ordersSlice.actions;
export default ordersSlice.reducer;