import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {asyncGetBasketsStatisticsAction, asyncGetOrdersStatisticsAction} from "./statisticsAction";

interface statisticsState {
    ordersStatistics: any;
    basketsStatistics: any;
    isLoading: boolean;
    error: string
}

const initialState: statisticsState = {
    ordersStatistics: null,
    basketsStatistics: null,
    isLoading: false,
    error: ''
};


const statisticsSlice = createSlice({
    name:'statisticsSlice',
    initialState,
    reducers:{
        resetStatistics(state){
            state = initialState;
        }
    },
    extraReducers: {
        [asyncGetOrdersStatisticsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.ordersStatistics = action.payload;
        },
        [asyncGetOrdersStatisticsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetOrdersStatisticsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },
        [asyncGetBasketsStatisticsAction.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.error = '';
            state.basketsStatistics = action.payload;
        },
        [asyncGetBasketsStatisticsAction.pending.type]: (state) => {
            state.isLoading = true;
        },
        [asyncGetBasketsStatisticsAction.rejected.type]: (state, action: PayloadAction<string>) => {
            state.isLoading = false;
            state.error = action.payload
        },

    }
});

export const {resetStatistics} = statisticsSlice.actions;
export default statisticsSlice.reducer;