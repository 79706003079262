import instance from "./api";
import {AxiosResponse} from 'axios';
import { IAttribute} from "../helpers/types";

export default class AttributesService {
    static async findAll(category:number): Promise<AxiosResponse<any>> {
        return instance.get<IAttribute[]>('attributes',
            {
                params: {
                    category,
                }
            }
            )
    }

    static async findOne(attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.get<IAttribute>(`attributes/${attributeID}` )
    }

    static async create(attribute:IAttribute): Promise<AxiosResponse<IAttribute>> {
        return instance.post<IAttribute>(`attributes`,attribute)
    }

    static async update(attribute:IAttribute,attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.put<IAttribute>(`attributes/${attributeID}`,attribute)
    }
    static async delete(attributeID:number): Promise<AxiosResponse<IAttribute>> {
        return instance.delete<IAttribute>(`attributes/${attributeID}`)
    }
}